import { useState } from "react";
import axios from "axios";
import style from "../../styles/AddInventory.module.css";
import ReactSelect from "../ReactSelect";
import AddForm from "../AddForm";
import ImageUploader from "../ImageUploader/ImageUploader";
import { useNavigate, useParams } from "react-router";
import { useEffect } from "react";
import ItemForm from "./ItemForm";
import { useRef } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getDefaultImage } from "./utility";
import LoaderOverlay from "../loaders/loaderOverlay";
import FileUplaodModal from "../Bulk-Upload/FileUplaodModal";
import PopUpModal from "../Modal";
import AddInventoryTour from "../../TourGuides/AddInventoryTour";

const InventoryForm = () => {
  const { user } = useSelector((state) => state.auth);
  const location = useSelector((state) => state.location.location);
  const [uploadShow, setUploadShow] = useState(false);
  const shop_id = user.shop_id;
  const [selectedType, setSelectedType] = useState({});
  const { mode, "*": id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const pageLoad = async () => {
      if (mode !== "add") {
        if (mode === "edit" || mode === "duplicate") {
          if (!id) {
            navigate("/");
          } else {
            if (id.split("/").length > 1) {
              navigate("/");
            } else {
              if (isNaN(Number(id))) {
                navigate("/");
              } else {
                try {
                  setIsFormSubmited(true);
                  const getItemResponse = await axios.get(process.env.REACT_APP_API_URL + `/inventory/` + id, {
                    withCredentials: true,
                  });
                  if (getItemResponse) {
                    // Item Object
                    const itemObj = getItemResponse.data.success.data.item;

                    // Images
                    const imagesArray = getItemResponse.data.success.data.images;

                    // Format Images to show in Image Component
                    const imagesPromiseArray = imagesArray.map(async (image, index) => {
                      if (image !== "nil") {
                        const obj = {
                          preview: process.env.REACT_APP_URL_S3 + "/" + image,
                          data: image,
                          type: "url",
                        };
                        return obj;
                      } else {
                        return "nil";
                      }
                    });
                    const imagesResolvedArray = await Promise.all(imagesPromiseArray);
                    // END of Format Images to show in Image Component

                    setImages({
                      oldValue: imagesResolvedArray,
                      value: imagesResolvedArray,
                      id: itemObj.images,
                    });
                    setSeletedCategory({
                      value: itemObj.category_id,
                      label: itemObj.category,
                    });
                    // setSelectedType({
                    //   value: itemObj.accessory_type_id,
                    //   label: itemObj.accessory_type,
                    // });
                    setItemState((oldState) => ({
                      ...oldState,
                      title: itemObj.title,
                      oldTitle: itemObj.title,
                      salePrice: itemObj.sale_price,
                      oldSalePrice: itemObj.sale_price,
                      location: {
                        value: itemObj.location_id,
                        label: itemObj.location_name,
                      },
                      oldLocation: {
                        value: itemObj.location_id,
                        label: itemObj.location_name,
                      },
                      condition: {
                        value: itemObj.condition_id,
                        label: itemObj.condition_item,
                      },
                      oldCondition: {
                        value: itemObj.condition_id,
                        label: itemObj.condition_item,
                      },
                      accessory: {
                        value: itemObj.accessory_type_id,
                        label: itemObj.accessory_type,
                      },
                      oldAccessory: {
                        value: itemObj.accessory_type_id,
                        label: itemObj.accessory_type,
                      },
                    }));
                    setIsFormSubmited(false);
                  }
                } catch (error) {
                  toast.warning(`You tried to access incorrect page.`, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1500,
                    pauseOnHover: false,
                    className: "toastMsg",
                  });
                }
              }
            }
          }
        } else {
          // navigate("/");
        }
      } else {
        if (id) {
          navigate("/");
        }
      }
    };
    pageLoad();
  }, [mode]);
  const [error, setError] = useState([]);
  const [isFormSubmited, setIsFormSubmited] = useState(false);
  const [formValidation, setFormValidation] = useState(false);
  const [selectedCategory, setSeletedCategory] = useState({
    value: 2,
    label: "Mobile",
  });
  const [images, setImages] = useState({ initialValue: [], value: [] });
  const setImagesValue = (valueOrCallback) => {
    if (typeof valueOrCallback === "function") {
      setImages((oldValue) => {
        const newValue = valueOrCallback(oldValue.value);
        return {
          ...oldValue,
          value: newValue,
        };
      });
    } else {
      setImages((oldValue) => ({ ...oldValue, value: valueOrCallback }));
    }
  };
  const imageComponent = useRef(null);

  const [itemState, setItemState] = useState({
    title: "",
    quantity: null,
    costPrice: null,
    salePrice: null,
    location: location,
    condition: null,
    accessory: null,
  });

  const isItemStateValid = () => {
    const numbericalFieldCheck = (value) => {
      if (mode === "edit" || mode === "duplicate") {
        return Number(value) !== 0 && value !== null;
      } else {
        return Number(value) !== 0 && value?.length > 0 && value !== null;
      }
    };
    if (Object.values(itemState).some((val) => val === "" || val === null)) {
      return itemState?.title !== "" && itemState?.condition !== null && itemState?.location !== null && numbericalFieldCheck(itemState?.salePrice) && (numbericalFieldCheck(itemState?.quantity) ? numbericalFieldCheck(itemState?.costPrice) : true);
    } else {
      return numbericalFieldCheck(itemState?.salePrice) && (numbericalFieldCheck(itemState?.quantity) ? numbericalFieldCheck(itemState?.costPrice) : true);
    }
  };

  const handleDependency = (e) => {
    setSeletedCategory(e);
  };

  const errorMessages = (error) => {
    setError(error);
  };

  const clearItemForm = () => {
    setItemState({
      title: "",
      quantity: null,
      costPrice: null,
      salePrice: null,
      location: location,
      condition: null,
      accessory: null,
    });
    setImages({ oldValue: [], value: [] });
  };

  const submitInventoryForm = async (e) => {
    setFormValidation(true);
    setIsFormSubmited(true);
    e.preventDefault();
    if (isItemStateValid()) {
      try {
        if (mode === "edit") {
          const imageURLs = await imageComponent.current.uploadImagesOnUpdate();
          let imageChanged = false;
          if (imageURLs.length === 0) {
            // if (images.oldValue.length > 0) {
            imageChanged = true;

            await axios.patch(
              process.env.REACT_APP_API_URL + `/items-images/update/` + images?.id,
              {
                images: getDefaultImage(selectedCategory?.value === 6 ? itemState.accessory.label : selectedCategory?.label),
                is_image_uploaded: false,
              },
              { withCredentials: true }
            );
            // }
          } else {
            if (images.oldValue.length !== imageURLs.length || imageURLs.some((file) => file.type === "file" || file.type === "tempUrl")) {
              imageChanged = true;
              const imageArrayForRequest = imageURLs.map((img) => (img.type === "file" ? img.preview : img.data));
              await axios.patch(
                process.env.REACT_APP_API_URL + `/items-images/update/` + images?.id,
                {
                  images: imageArrayForRequest,
                  is_image_uploaded: true,
                },
                { withCredentials: true }
              );
            }
          }
          if (itemState.accessory.value === itemState.oldAccessory?.value && itemState?.salePrice === itemState?.oldSalePrice && itemState?.title === itemState?.oldTitle && itemState?.condition?.value === itemState?.oldCondition?.value && !imageChanged) {
            toast.info(`Nothing to change`, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 1500,
              pauseOnHover: false,
              className: "toastMsg",
            });
            setIsFormSubmited(false);

            return;
          }
          const inventoryRequestBody = {
            sale_price: itemState?.salePrice,
            title: itemState?.title,
            condition_item: itemState?.condition?.label,
            condition_id: itemState?.condition?.value,
            accessory_type_id: selectedCategory.value === 6 ? itemState?.accessory.value : -1,
            accessory_type: selectedCategory.value === 6 ? itemState?.accessory.label : "nil",
          };
          const updateInventoryResponse = await axios.patch(process.env.REACT_APP_API_URL + `/inventory/` + id, inventoryRequestBody, {
            withCredentials: true,
          });
          if (updateInventoryResponse) {
            setFormValidation(false);
            setIsFormSubmited(false);
            clearItemForm();
            if (updateInventoryResponse.data.code === 304 && !imageChanged) {
              toast.info(`Nothing to change`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1500,
                pauseOnHover: false,
                className: "toastMsg",
              });
            } else {
              toast.success(`Inventory successfully updated!`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1500,
                pauseOnHover: false,
                className: "toastMsg",
              });
            }
            navigate("/inventory");
          }
        } else {
          let imageURLs = "";
          if (images.value.length === 0) {
            imageURLs = getDefaultImage(selectedCategory?.value === 6 ? itemState.accessory.label : selectedCategory?.label);
          } else {
            imageURLs = await imageComponent.current.uploadingImages();
          }
          const resImage = await axios.post(
            process.env.REACT_APP_API_URL + `/items-images/add`,
            {
              images: imageURLs.map((img) => (img.hasOwnProperty("type") ? (img.type === "file" ? img.preview : img.data) : img)),
              is_image_uploaded: images.value.length > 0 ? true : false,
            },
            { withCredentials: true }
          );
          const imageId = resImage.data.insertId;
          if (imageId) {
            const inventoryRequestBody = {
              shop_id: shop_id,
              location_id: location?.value,
              category_id: selectedCategory?.value,
              accessory_type_id: selectedCategory.value === 6 ? itemState?.accessory.value : -1,
              accessory_type: selectedCategory.value === 6 ? itemState?.accessory.label : "nil",
              condition_item: itemState?.condition?.label,
              condition_id: itemState?.condition?.value,
              cost_price: itemState?.quantity && +itemState?.quantity > 0 ? (itemState?.costPrice ? itemState?.costPrice : 0) : 0,
              sale_price: itemState?.salePrice,
              quantity: itemState?.quantity ? itemState?.quantity : 0,
              title: itemState?.title,
              images: imageId,
            };
            const addInventoryResponse = await axios.post(process.env.REACT_APP_API_URL + `/inventory`, inventoryRequestBody, {
              withCredentials: true,
            });
            if (addInventoryResponse) {
              setFormValidation(false);
              setIsFormSubmited(false);
              clearItemForm();
              toast.success(`Successfully added ${itemState?.title}`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1500,
                pauseOnHover: false,
                className: "toastMsg",
              });
              navigate("/inventory");
            }
          }
        }
      } catch (error) {
        toast.error(`Error while adding inventory`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1500,
          pauseOnHover: false,
          className: "toastMsg",
        });

        setError(error.response.data.message);
        setIsFormSubmited(false);
      }
    } else {
      setIsFormSubmited(false);
    }
  };
  return (
    <>
      {user?.tour && !user?.tour?.AddInventory && <AddInventoryTour />}
      <AddForm>
        <div className="d-flex justify-content-between align-items-center px-5">
          <h5>
            <span className="text-capitalize">{mode}</span> Inventory
          </h5>
          <div className={`${style.upload_btn_wrapper}`}>
            <Button
              id="inventory-request"
              onClick={() => {
                setUploadShow(true);
              }}
            >
              Request Inventory Import
            </Button>
          </div>
        </div>

        {error?.length > 0 && <p className="text-danger p-2 text-center my-2 rounded-1">*{error}</p>}

        <hr />
        <Row className={`py-3 ${style.formMain} ${style.selectCAt}`}>
          <div className={selectedCategory.value === 6 ? `col-12 col-md-6 col-lg-6` : ``} id="inventory-form-category">
            {/* <div className={selectedCategory.value !== 6 ? `${style.formMain}  py-3 ${style.selectCAt}` : `col-12 col-md-6 col-lg-6 ${style.formMain}  py-3 ${style.selectCAt}`}> */}
            <ReactSelect
              label={"Category"}
              important
              placeholder="Select Category"
              searchable={false}
              value={selectedCategory}
              options={() => {
                return new Promise((resolve, reject) => {
                  axios
                    .get(process.env.REACT_APP_API_URL + "/categories/get", {
                      withCredentials: true,
                    })
                    .then((res) => {
                      const getData = res.data.map((cat) => ({
                        value: cat.id,
                        label: cat.category,
                      }));
                      resolve(getData);
                    })
                    .catch((err) => {
                      reject("err" + err);
                    });
                });
              }}
              handleChange={handleDependency}
              disabled={mode === "edit"}
            />
          </div>
          {selectedCategory.value === 6 ? (
            // <Col className="py-3" sm="6" md={6}>
            <div className={`col-12 col-md-6 col-lg-6`}>
              <Form.Group>
                <Form.Control
                  as={ReactSelect}
                  label={"Type"}
                  important
                  placeholder="Select Accessory Type"
                  searchable={true}
                  cache={false}
                  options={(e) => {
                    return new Promise((resolve, reject) => {
                      axios
                        .get(process.env.REACT_APP_API_URL + `/component/accessory_type?${new URLSearchParams({ dropdown: true }).toString()}`, {
                          withCredentials: true,
                        })
                        .then((res) => {
                          const value = res.data.success.data.map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));

                          const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                          resolve(filteredValue);
                        })
                        .catch((error) => {
                          throw new Error(error);
                        });
                    });
                  }}
                  handleChange={(e) => setItemState((oldValue) => ({ ...oldValue, accessory: e }))}
                  value={itemState?.accessory}
                  isInvalid={formValidation && selectedCategory.value === 6 && !itemState?.accessory?.value}
                  name="Accessory Type"
                  required
                />
                <Form.Control.Feedback type="invalid"> * Please Select Accessory Type </Form.Control.Feedback>
              </Form.Group>
              {/* </Col> */}
            </div>
          ) : (
            ""
          )}
        </Row>
        <hr className={`${style.borderedHr}`} />
        <div className={`${style.formMain} `}>
          <ItemForm mode={mode} validated={formValidation} state={itemState} setState={setItemState} />
        </div>
        <hr className={`${style.borderedHr}`} />
        <div className={`${style.formMain} `}>
          <ImageUploader ref={imageComponent} selectedFiles={images.value} setSelectedFiles={setImagesValue} />
        </div>
        {error && <p className="text-center text-danger">{error}</p>}
        <Row className={`${style.formMain} py-3`}>
          <Col>
            <Button type="clear" className="w-100 " variant="secondary" onClick={mode === "add" ? clearItemForm : () => navigate("/inventory")} id="inventory-form-list-button">
              {mode === "add" ? "Clear" : "Back to List"}
            </Button>
          </Col>
          <Col>
            <Button
              id="inventory-form-save-button"
              type="submit"
              className="w-100"
              // disabled={isFormSubmited === false ? false : true}
              onClick={submitInventoryForm}
            >
              {mode === "edit" ? "Update" : "Save"}
            </Button>
          </Col>
        </Row>
      </AddForm>
      <PopUpModal
        title="File Upload"
        show={uploadShow}
        onHide={() => {
          setUploadShow(false);
        }}
      >
        <FileUplaodModal onClose={() => setUploadShow(false)} />
      </PopUpModal>
      <LoaderOverlay loading={isFormSubmited} />
    </>
  );
};

export default InventoryForm;
