import React from "react";
import { Card, Col, Row, Form, Table, Button } from "react-bootstrap";
import style from "../../styles/Reports.module.css";
import insightsup from "../../assets/svg/sales.svg";
import dollarliability from "../../assets/svg/newliability.svg";
import dollarexpenses from "../../assets/svg/newExpense.svg";
import customers from "../../assets/svg/customers.svg";
import VerticalBarChart from "../Charts/VerticalBarChart";
import { useState, useEffect, useRef } from "react";
import { CSVLink } from "react-csv";
import moment from "moment";
import { useSelector } from "react-redux";
import axios from "axios";
import SearchableSelect from "../SearchableSelect";
import { requestDateTime } from "../../Features/dateTime";
import DatePicker from "../DatePicker/DatePicker";
import { useSpring, animated } from "react-spring";
import ReactToPrint from "react-to-print";
import print from "../../assets/svg/printIcon.svg";
import PrintProfitLossReport from "./PrintProfitLossReport";
import { inWords, moneyToMillions } from "./InventoryReports/numberToWords";
import BarChart from "../Charts/BarChart";
import LineChart from "../Charts/LineChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTrendDown, faArrowTrendUp, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import MobilrReportsFilter from "./MobileReportsFilter";
import LoaderPage from "../loaders/loaderPage";
import ProfitLossReportTour from "../../TourGuides/ProfitLossReportTour";
const CurrencyAnimation = (props) => {
  const value = useSpring({
    from: { number: 0 },
    to: { number: props.number },
    delay: 200,
    config: { mass: 1, tension: 200, friction: 10 },
  });
  return (
    <animated.div>
      {value.number.to((n) => {
        const tempN = Number(n.toFixed(0));
        return tempN.toLocaleString("en-IN");
      })}
    </animated.div>
  );
};

const ProfitLossReport = ({ MainactiveTab, close, show }) => {
  const [data, setData] = useState();
  const profitLossReportComponentRef = useRef(null);
  const [overView, setOverView] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [fromDate, setFromDate] = useState(moment().subtract(29, "days").startOf("day")._d);
  const [toDate, setToDate] = useState(moment().endOf("day")._d);
  const [queue, setQueue] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const shop_id = user.shop_id;
  const user_type = user.user_type;
  const userId = user.user_id;
  const shopName = user.shop_name;
  const [location, setLocation] = useState({});
  const [locationFields, setLocationFields] = useState({});

  const enqueueApiCall = (apiCall) => {
    setQueue((prevQueue) => [...prevQueue, apiCall]);
  };
  useEffect(() => {
    axios
      .post(process.env.REACT_APP_API_URL + `/location/get-location-by-user/${userId}`, { shop_id: shop_id, user_type: user_type }, { withCredentials: true })
      .then((res) => {
        const locationsArr = res.data.map((loc) => ({
          value: loc.location_id,
          label: loc.location,
          isDisabled: new Date().toISOString() >= loc.active_till ? true : false,
        }));

        setLocation(locationsArr);
        setLocationFields({
          value: locationsArr[0]?.value,
          label: locationsArr[0]?.label,
          isDisabled: locationsArr[0]?.isDisabled,
        });
      })
      .catch((err) => {
        throw new Error(err);
      });
  }, [userId, shop_id, user_type]);

  useEffect(() => {
    setIsLoading(true);
    const getChartData = () => {
      const queryString = new URLSearchParams({
        ...(locationFields?.value ? { location: locationFields.value } : {}),
        ...(fromDate && toDate ? { "date[from]": requestDateTime(fromDate).toISOString(), "date[to]": requestDateTime(toDate).toISOString() } : {}),
      });
      axios
        .get(process.env.REACT_APP_API_URL + `/reports/profit-loss-chart/${shop_id}${queryString?.toString()?.length ? `?${queryString.toString()}` : ""}`, {
          withCredentials: true,
        })
        .then((res) => {
          setIsLoading(false);
          // data
          const Glabel = res.data.costOfGoodSold.map((data) => data.label);
          const GData = res.data.costOfGoodSold.map((data) => data.costOfGoodSold);
          const totalRevenueData = res.data.totalSalesRevenue.map((data) => data.totalSalesRevenue);
          const grossProfitData = res.data.grossProfit.map((data) => data.grossProfit);
          const operatinExpenseData = res.data.operatingExpenses.map((data) => data.operatingExpenses);
          const Netloss = res.data.netProfit.map((data) => (data.hasOwnProperty("netProfit") ? data.netProfit : data.netLoss));

          //Mean
          const GDataMean = res.data.costOfGoodSold.map((data) => data.mean);
          const totalRevenueMean = res.data.totalSalesRevenue.map((data) => data.mean);
          const grossProfitMean = res.data.grossProfit.map((data) => data.mean);
          const operatinExpenseMean = res.data.operatingExpenses.map((data) => data.mean);
          const NetlossMean = res.data.netProfit.map((data) => data.mean);

          // Trend
          const GDataInc = res.data.costOfGoodSold.filter((data) => data.trend === "increase");
          const GDataDec = res.data.costOfGoodSold.filter((data) => data.trend === "decrease");
          const totalRevenueInc = res.data.totalSalesRevenue.filter((data) => data.trend === "increase");
          const totalRevenueDec = res.data.totalSalesRevenue.filter((data) => data.trend === "decrease");
          const grossProfitInc = res.data.grossProfit.filter((data) => data.trend === "increase");
          const grossProfitDec = res.data.grossProfit.filter((data) => data.trend === "decrease");
          const operatinExpenseInc = res.data.operatingExpenses.filter((data) => data.trend === "increase");
          const operatinExpenseDec = res.data.operatingExpenses.filter((data) => data.trend === "decrease");
          const NetlossInc = res.data.netProfit.filter((data) => data.trend === "increase");
          const NetlossDec = res.data.netProfit.filter((data) => data.trend === "decrease");
          setChartData({
            SalesRevenue: {
              labels: Glabel,
              Trend: totalRevenueInc.length > totalRevenueDec.length ? "up" : "down",
              datasets: [
                {
                  label: "Sales Revenue",
                  data: totalRevenueData,
                  backgroundColor: "rgba(16, 1, 70, 1)",
                  borderColor: "rgba(16, 1, 70, 1)",
                },
                {
                  label: "Mean",
                  data: totalRevenueMean,
                  backgroundColor: "rgb(75, 192, 192)",
                  borderColor: "rgb(75, 192, 192)",
                },
              ],
            },
            CostOfGood: {
              labels: Glabel,
              Trend: GDataInc.length > GDataDec.length ? "up" : "down",
              datasets: [
                {
                  label: "Cost of Good Sold ",
                  data: GData,
                  backgroundColor: "rgba(128, 49, 170, 1)",
                  borderColor: "rgba(128, 49, 170, 1)",
                },
                {
                  label: "Mean",
                  data: GDataMean,
                  backgroundColor: "rgb(75, 192, 192)",
                  borderColor: "rgb(75, 192, 192)",
                },
              ],
            },
            GrossProfit: {
              labels: Glabel,
              Trend: grossProfitInc.length > grossProfitDec.length ? "up" : "down",
              datasets: [
                {
                  label: "Gross Profit ",
                  data: grossProfitData,
                  backgroundColor: "rgba(89, 50, 234, 1)",
                  borderColor: "rgba(89, 50, 234, 1)",
                },
                {
                  label: "Mean",
                  data: grossProfitMean,
                  backgroundColor: "rgb(75, 192, 192)",
                  borderColor: "rgb(75, 192, 192)",
                },
              ],
            },
            OperatingExpense: {
              labels: Glabel,
              Trend: operatinExpenseInc.length > operatinExpenseDec.length ? "up" : "down",
              datasets: [
                {
                  label: "Operating Expense ",
                  data: operatinExpenseData,
                  backgroundColor: "rgba(225, 102, 255, 1)",
                  borderColor: "rgba(225, 102, 255, 1)",
                },
                {
                  label: "Mean",
                  data: operatinExpenseMean,
                  backgroundColor: "rgb(75, 192, 192)",
                  borderColor: "rgb(75, 192, 192)",
                },
              ],
            },
            NetLoss: {
              labels: Glabel,
              Trend: NetlossInc.length > NetlossDec.length ? "up" : "down",
              datasets: [
                {
                  label: "Net Loss ",
                  data: Netloss,
                  backgroundColor: "rgba(175, 69, 212, 1)",
                  borderColor: "rgba(175, 69, 212, 1)",
                },
                {
                  label: "Mean",
                  data: NetlossMean,
                  backgroundColor: "rgb(75, 192, 192)",
                  borderColor: "rgb(75, 192, 192)",
                },
              ],
            },
          });
        })
        .catch((err) => {
          throw new Error(err);
          setIsLoading(false);
        });
    };
    const getReport = () => {
      setIsLoading(true);
      const queryString = new URLSearchParams({
        ...(locationFields?.value ? { location: locationFields.value } : {}),
        ...(fromDate && toDate ? { "date[from]": requestDateTime(fromDate).toISOString(), "date[to]": requestDateTime(toDate).toISOString() } : {}),
      });
      axios
        .get(process.env.REACT_APP_API_URL + `/reports/profit-loss/${shop_id}${queryString?.toString()?.length ? `?${queryString.toString()}` : ""}`, {
          withCredentials: true,
        })
        .then((res) => {
          setData(res.data.success.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          throw new Error(err);
        });
    };
    if (locationFields?.value && shop_id) {
      getReport();
      getChartData();
    }
  }, [shop_id, fromDate, toDate, locationFields]);

  const handleLocation = (e) => {
    if (e.value) {
      setLocationFields(e);
    } else {
      setLocationFields({});
    }
  };
  const convertDate = (date) => {
    const inputDate = new Date(date);

    // Format the date as "jul 11 2023"
    // const months = [
    //   "jan",
    //   "feb",
    //   "mar",
    //   "apr",
    //   "may",
    //   "jun",
    //   "jul",
    //   "aug",
    //   "sep",
    //   "oct",
    //   "nov",
    //   "dec",
    // ];
    var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const formattedDate = months[inputDate.getMonth()] + " " + inputDate.getDate() + " " + inputDate.getFullYear();
    return formattedDate;
  };

  return (
    <>
      {user?.tour && !user?.tour?.ProfitLossReport && <ProfitLossReportTour />}
      <div className="d-none">
        <PrintProfitLossReport ref={profitLossReportComponentRef} fromDate={fromDate} toDate={toDate} location={locationFields} data={data} />
      </div>
      <Row className="align-items-center  px-2 py-2 bg-white my-4" id="profit-loss-report-filters">
        <Col className="mb-2 d-lg-block d-md-block d-sm-block d-none" md="4" sm="4" lg="3" xl="2" id="profit-loss-report-day-filter">
          <DatePicker fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
        </Col>
        <Col className="mb-2 mb-md-0 d-lg-block d-md-block d-sm-block d-none " md="4" sm="4" lg="3" xl="2" id="profit-loss-report-location-filter">
          <SearchableSelect placeholder="Select Location" searchable={false} options={location} handleChange={handleLocation} value={Object.keys(locationFields).length > 0 ? locationFields : null} style={{ width: "100px" }} disabled={!location.length} />
        </Col>

        <Col sm="4" md="4" lg="4" xl="2" className="d-sm-block" id="profit-loss-report-print">
          <ReactToPrint
            trigger={() => (
              <Button variant="outline-primary fs-14" style={{ height: "38px" }}>
                <img src={print} alt="removeIcon" className="me-2" style={{ marginTop: "-3px" }} />
                Print
              </Button>
            )}
            content={() => profitLossReportComponentRef.current}
            documentTitle="Daily Cash Report"
          >
            {" "}
          </ReactToPrint>
        </Col>
      </Row>
      {isLoading ? (
        <div style={{ height: "50vh" }} className="">
          <LoaderPage />
        </div>
      ) : (
        <>
          <Row className="py-1 mb-5 bg-white">
            <div className="p-2 fw-bold fs-5 d-flex align-items-baseline">
              Profit/Loss Report <p className="text-muted fs-16 ms-2">{`${convertDate(fromDate)}` === `${convertDate(toDate)}` ? convertDate(toDate) : `${convertDate(fromDate)} - ${convertDate(toDate)}`} </p>{" "}
            </div>
            <Col xl="6" lg="6" md="6" className="bg-white">
              <div className=" d-grid bg-white" id="profit-loss-report-sales-revenue">
                <Card className={`${style.cards} border-0 py-2 w-100  reports-card`}>
                  <span className="border-bottom text-start px-2">Sales Revenue</span>

                  <div className=" d-flex ms-4 flex-md-row flex-column">
                    <hr className={`${style.LegendsLinePsold} me-3 `} width="5" size="40" />

                    <div className="text-md-start text-center w-100 ">
                      <Card.Text className={` ${style.cardstxt} h-100  d-grid mt-3`}>
                        <div className="d-flex justify-content-between ">
                          <div className="d-grid">
                            <span className={`${style.cardstitle}`}>Rs {moneyToMillions(data?.salesRevenue) ? moneyToMillions(data?.salesRevenue) : "--"}</span>
                            <span className="text-muted fs-14 ">Exact Rs {data?.salesRevenue ? data?.salesRevenue : "--"} </span>
                          </div>
                          {chartData?.SalesRevenue?.Trend === "up" ? (
                            <FontAwesomeIcon style={{ height: "25px", width: "25px" }} className="me-3" icon={faArrowTrendUp} />
                          ) : (
                            <FontAwesomeIcon
                              style={{
                                height: "25px",
                                width: "25px",
                                color: "red",
                              }}
                              className="me-3"
                              icon={faArrowTrendDown}
                            />
                          )}
                        </div>
                      </Card.Text>
                    </div>
                  </div>
                  <div className={`${style.item}`}>
                    <LineChart data={chartData.SalesRevenue} />
                  </div>
                </Card>
              </div>
            </Col>
            <Col xl="6" lg="6" md="6" className="mb-3">
              <div className="h-100 d-grid" id="profit-loss-report-goods-sold">
                <Card className={`${style.cards} border-0 w-100 mx-2   reports-card`}>
                  <span className="border-bottom text-start px-2">Cost Of Good Sold</span>

                  <div className=" d-flex  ms-4 flex-md-row flex-column">
                    <hr className={`${style.LegendsLineTsales} me-3 `} width="5" size="40" />
                    <div className="text-md-start  mb-3 text-start w-100">
                      <Card.Text className={` ${style.cardstxt} h-100  d-grid  mt-3`}>
                        <div className="d-flex justify-content-between ">
                          <div className="d-grid">
                            <span className={`${style.cardstitle}`}>Rs {moneyToMillions(data?.costOfGoodSold) ? moneyToMillions(data?.costOfGoodSold) : "--"}</span>
                            <span className="text-muted fs-14 ">Exact Rs {data?.costOfGoodSold ? data?.costOfGoodSold : "--"} </span>
                          </div>
                          {chartData?.CostOfGood?.Trend === "up" ? (
                            <FontAwesomeIcon style={{ height: "25px", width: "25px" }} className="me-3" icon={faArrowTrendUp} />
                          ) : (
                            <FontAwesomeIcon
                              style={{
                                height: "25px",
                                width: "25px",
                                color: "red",
                              }}
                              className="me-3"
                              icon={faArrowTrendDown}
                            />
                          )}
                        </div>
                      </Card.Text>
                    </div>
                  </div>
                  <div className={`${style.item}`}>
                    <LineChart data={chartData.CostOfGood} />
                  </div>
                </Card>
              </div>
            </Col>
            <Col xl="6" lg="6" md="6" className="bg-white">
              <div className=" d-grid bg-white" id="profit-loss-report-gross-profit">
                <Card className={`${style.cards} border-0 py-2 w-100 reports-card`}>
                  <span className="border-bottom text-start px-2">Gross Profit</span>

                  <div className=" d-flex ms-4 flex-md-row flex-column">
                    <hr className={`${style.LegendsLineNoSales} me-3 `} width="5" size="40" />

                    <div className="text-md-start text-center w-100">
                      <Card.Text className={` ${style.cardstxt} h-100 d-grid mt-3`}>
                        <div className="d-flex justify-content-between ">
                          <div className="d-grid">
                            <span className={`${style.cardstitle}`}>Rs {moneyToMillions(data?.grosProfit) ? moneyToMillions(data?.grosProfit) : "--"}</span>
                            <span className="text-muted fs-14 ">Exact Rs {data?.grosProfit ? data?.grosProfit : "--"} </span>
                          </div>
                          {chartData?.GrossProfit?.Trend === "up" ? (
                            <FontAwesomeIcon style={{ height: "25px", width: "25px" }} className="me-3" icon={faArrowTrendUp} />
                          ) : (
                            <FontAwesomeIcon
                              style={{
                                height: "25px",
                                width: "25px",
                                color: "red",
                              }}
                              className="me-3"
                              icon={faArrowTrendDown}
                            />
                          )}
                        </div>
                      </Card.Text>
                    </div>
                  </div>
                  <div className={`${style.item}`}>
                    <LineChart data={chartData.GrossProfit} />
                  </div>
                </Card>
              </div>
            </Col>
            <Col xl="6" lg="6" md="6" className="bg-white">
              <div className=" d-grid bg-white" id="profit-loss-report-operating-expenses">
                <Card className={`${style.cards} border-0 w-100 mx-2 py-2 pb-3 sreports-card`}>
                  <span className="border-bottom text-start px-2">Operating Expense</span>

                  <div className=" d-flex ms-4 flex-md-row flex-column">
                    <hr className={`${style.LegendsLineDiscount} me-3 `} width="5" size="40" />

                    <div className="text-md-start  w-100 text-center">
                      <Card.Text className={` ${style.cardstxt} h-100 mt-3 d-grid`}>
                        <div className="d-flex justify-content-between ">
                          <div className="d-grid">
                            <span className={`${style.cardstitle}`}>Rs {moneyToMillions(data?.operatingExpense) ? moneyToMillions(data?.operatingExpense) : "--"}</span>
                            <span className="text-muted fs-14 ">Exact Rs {data?.operatingExpense ? data?.operatingExpense : "--"} </span>
                          </div>
                          {chartData?.OperatingExpense?.Trend === "up" ? (
                            <FontAwesomeIcon style={{ height: "25px", width: "25px" }} className="me-3" icon={faArrowTrendUp} />
                          ) : (
                            <FontAwesomeIcon
                              style={{
                                height: "25px",
                                width: "25px",
                                color: "red",
                              }}
                              className="me-3"
                              icon={faArrowTrendDown}
                            />
                          )}
                        </div>
                      </Card.Text>
                    </div>
                  </div>
                  <div className={`${style.item}`}>
                    <LineChart data={chartData.OperatingExpense} />
                  </div>
                </Card>
              </div>
            </Col>
            <Col xl="6" lg="6" md="6" className="bg-white">
              <div className=" d-grid bg-white" id="profit-loss-report-net-profit">
                <Card className={`${style.cards} border-0 w-100 mx-2 py-2 pb-3 sreports-card`}>
                  <span className="border-bottom text-start px-2">{data?.netValue < 0 ? "Net Loss" : "Net Profit"}</span>

                  <div className=" d-flex ms-4 flex-md-row flex-column">
                    <hr className={`${style.LegendsLineDiscount} me-3 `} width="5" size="40" />

                    <div className="text-md-start  w-100 text-center">
                      <Card.Text className={` ${style.cardstxt} h-100 mt-3 d-grid`}>
                        <div className="d-flex justify-content-between ">
                          <div className="d-grid">
                            <span className={`${style.cardstitle}`}>Rs {moneyToMillions(data?.netValue) ? moneyToMillions(data?.netValue) : "--"}</span>
                            <span className="text-muted fs-14 ">Exact Rs {data?.netValue ? data?.netValue : "--"} </span>
                          </div>
                          {chartData?.NetLoss?.Trend === "up" ? (
                            <FontAwesomeIcon style={{ height: "25px", width: "25px" }} className="me-3" icon={faArrowTrendUp} />
                          ) : (
                            <FontAwesomeIcon
                              style={{
                                height: "25px",
                                width: "25px",
                                color: "red",
                              }}
                              className="me-3"
                              icon={faArrowTrendDown}
                            />
                          )}
                        </div>
                      </Card.Text>
                    </div>
                  </div>
                  <div className={`${style.item}`}>
                    <LineChart data={chartData.NetLoss} />
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
          <Row className="w-100 bg-white py-4 p-2">
            <Col xs={12} className="d-flex d-lg-flex d-md-flex d-sm-flex mt-3 ">
              <div className="w-100 d-block d-lg-none d-md-none d-sm-none">
                <p className="p-3 mb-0 border align-items-center gap-2 d-flex rounded-top bg-white w-100" onClick={() => setOverView((prev) => !prev)}>
                  {overView ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />} Over view{" "}
                </p>
                <Card className={` border-1 d-flex py-2 ${overView ? "" : "d-none"}  `}>
                  <div className="d-flex ms-2 align-items-center ">
                    <hr className={`${style.LegendsLinePsold} me-3 `} width="5" size="40" />

                    <div className="ms-2">
                      <Card.Text className={` d-grid  `}>
                        <span>Sales Revenue</span>
                        <span className={` fs-20 fw-bold  `}>
                          Rs {moneyToMillions(data?.salesRevenue) ? moneyToMillions(data?.salesRevenue) : "--"}
                          <span className="fs-13 text-muted"> Exact {data?.salesRevenue ? data?.salesRevenue : "--"} </span>
                        </span>
                      </Card.Text>
                    </div>
                  </div>
                </Card>
                <Card className={` border-1 d-flex py-2 ${overView ? "" : "d-none"}  `}>
                  <div className="d-flex ms-2 align-items-center ">
                    <hr className={`${style.LegendsLineTsales} me-3 `} width="5" size="40" />

                    <div className="ms-2">
                      <Card.Text className={` d-grid  `}>
                        <span>Cost of Goods Sold</span>
                        <span className={` fs-20 fw-bold  `}>
                          Rs {moneyToMillions(data?.costOfGoodSold) ? moneyToMillions(data?.costOfGoodSold) : "--"}
                          <span className="fs-13 text-muted"> Exact {data?.costOfGoodSold ? data?.costOfGoodSold : "--"} </span>
                        </span>
                      </Card.Text>
                    </div>
                  </div>
                </Card>
                <Card className={` border-1 d-flex py-2 ${overView ? "" : "d-none"}  `}>
                  <div className="d-flex ms-2 align-items-center ">
                    <hr className={`${style.LegendsLineNoSales} me-3 `} width="5" size="40" />

                    <div className="ms-2">
                      <Card.Text className={` d-grid  `}>
                        <span> Gross Profit</span>
                        <span className={` fs-20 fw-bold  `}>
                          Rs {moneyToMillions(data?.grosProfit) ? moneyToMillions(data?.grosProfit) : "--"}
                          <span className="fs-13 text-muted"> Exact {data?.grosProfit ? data?.grosProfit : "--"} </span>
                        </span>
                      </Card.Text>
                    </div>
                  </div>
                </Card>
                <Card className={` border-1 d-flex py-2 ${overView ? "" : "d-none"}  `}>
                  <div className="d-flex ms-2 align-items-center ">
                    <hr className={`${style.LegendsLineDiscount} me-3 `} width="5" size="40" />

                    <div className="ms-2">
                      <Card.Text className={` d-grid  `}>
                        <span> Operating Expenses</span>
                        <span className={` fs-20 fw-bold  `}>
                          Rs {moneyToMillions(data?.operatingExpense) ? moneyToMillions(data?.operatingExpense) : "--"}
                          <span className="fs-13 text-muted"> Exact {data?.operatingExpense ? data?.operatingExpense : "--"} </span>
                        </span>
                      </Card.Text>
                    </div>
                  </div>
                </Card>
                <Card className={` border-1 d-flex py-2 ${overView ? "" : "d-none"}  `}>
                  <div className="d-flex ms-2 align-items-center ">
                    <hr className={`${style.LegendsLineDiscount} me-3 `} width="5" size="40" />

                    <div className="ms-2">
                      <Card.Text className={` d-grid  `}>
                        <span> Net Loss </span>
                        <span className={` fs-20 fw-bold  `}>
                          Rs {moneyToMillions(data?.netValue) ? moneyToMillions(data?.netValue) : "--"}
                          <span className="fs-13 text-muted"> Exact {data?.netValue ? data?.netValue : "--"} </span>
                        </span>
                      </Card.Text>
                    </div>
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </>
      )}
      <MobilrReportsFilter handleLocation={handleLocation} location={location} locationFields={locationFields} setLocationFields={setLocationFields} activeTab={MainactiveTab} show={show} handleClose={close} fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
    </>
  );
};

export default ProfitLossReport;
