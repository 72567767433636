import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import axios from "axios";
import Csv from "../../csv/Csv";
import CustomizeTableview from "../../TableViews/CustomizeTableview";
import PaginationBar from "../../PaginationBar";
import Sttyle from "../../../styles/ProgressBar.module.css";
import { requestDateTime } from "../../../Features/dateTime";

function InventoryReportTab({ filter, shopId, fromDate, toDate, location, brand, category, vendor, product }) {
  let [items, setItems] = useState([]);
  let [isLoading, setIsLoading] = useState(true);
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);

  const changePage = (page) => {
    setCurrentPage(page);
  };
  const queryString = new URLSearchParams({
    ...(currentPage ? { page: currentPage } : {}),
    ...(sizeOfPages ? { size: sizeOfPages } : {}),
    ...(location?.value ? { location: location?.value } : {}),
    ...(product?.value ? { item: product?.value } : {}),
    ...(brand?.value ? { brand: brand?.value } : {}),
    ...(category?.value ? { category: category?.value } : {}),
    ...(vendor?.value ? { vendor: vendor?.value } : {}),
    ...(filter ? filter : {}),
    ...(fromDate && toDate ? { "date[from]": requestDateTime(fromDate).toISOString(), "date[to]": requestDateTime(toDate).toISOString() } : {}),
  });
  useEffect(() => {
    try {
      const getData = async () => {
        const res = await axios.get(process.env.REACT_APP_API_URL + `/reports/inventory-report/${shopId}${queryString?.toString()?.length ? `?${queryString.toString()}` : ""}`, {
          withCredentials: true,
        });
        if (res.data.code === 200) {
          const data = res.data.success.data.itemReport;
          setItems(data);
        } else {
          setItems([]);
        }
        setIsLoading(false);
      };
      const getNoOfPages = async () => {
        const res = await axios.get(process.env.REACT_APP_API_URL + `/reports/inventory-report/no-of-pages/${shopId}${queryString?.toString()?.length ? `?${queryString.toString()}` : ""}`, {
          withCredentials: true,
        });
        setNoOfPages(res.data.success.no_of_pages);
        setCount(res.data.success.no_of_items);
        setIsLoading(false);
      };
      if (location?.value) {
        setIsLoading(true);
        getNoOfPages();
        getData();
      }
    } catch (error) {
      setItems([]);
      setIsLoading(false);
      throw new Error(error.response.data);
    }
  }, [filter, shopId, fromDate, toDate, location, brand, category, vendor, product, currentPage]);
  useEffect(() => {
    setCurrentPage(1);
  }, [shopId, fromDate, toDate, location, brand, category, vendor, product]);

  return (
    <>
      {isLoading ? (
        <div className={Sttyle.statusBar}></div>
      ) : items.length === 0 ? (
        <Container fluid className="d-flex justify-content-center align-items-center" style={{ height: "40vh" }}>
          <div className="text-center">
            {/* <img height="45px" width="45px" alt="search" className="mb-3" /> */}
            <h5 className="text-dark">No result found!</h5>
            <p className="text-secondary">0 result.</p>
            <h5>Thanks for visiting!</h5>
          </div>
        </Container>
      ) : (
        <>
          <CustomizeTableview data={items} actions={false} columnNotShow={["id"]} />
          <Row className="mt-5 align-items-center">
            <Col className="d-none d-sm-block" sm="2" md="3" lg="3">
              <span className="text-secondary fs-6">{count} result(s)</span>
            </Col>
            <Col xs="12" sm="7" md="6" lg="6">
              <PaginationBar currentPage={currentPage} noOfPages={noOfPages} changePage={changePage} />
            </Col>
            <Col className="d-sm-none d-flex align-items-center mt-3" xs="6" lg="3">
              <span className="text-secondary fs-6">{count} result(s)</span>
            </Col>
            {/* <Col xs="6" sm="3" md="3" lg="3" className="text-end pe-2 px-0">
              <Csv
                filename={`${
                  "Inventory " +
                  " " +
                  new Date().toLocaleDateString("en-US", {
                    timeZone: "Asia/Karachi",
                  })
                }`}
                api={
                  // requestAllHistory
                  () => {
                    return new Promise((resolve, reject) => {
                      resolve();
                    });
                  }
                }
                format={(data) => {
                  const {
                    id,
                    img0,
                    category_id,
                    created_at,
                    updated_at,
                    ...temp
                  } = data;
                  const newObj = {
                    ...temp,
                    created_at: created_at.split("T")[0],
                    updated_at:
                      updated_at === null ? null : updated_at.split("T")[0],
                  };
                  return newObj;
                }}
                error={(err) => {
                  const tempErr = err.response.data.error;
                  if (
                    tempErr.status === 404 &&
                    tempErr.message === "No Record Found"
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                }}
              />
            </Col> */}
          </Row>
        </>
      )}
    </>
  );
}

export default InventoryReportTab;
