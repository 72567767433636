import { useState } from "react";
import { Form } from "react-bootstrap";
import axios from "axios";
import { useEffect } from "react";
import { requestDateTime } from "../../Features/dateTime";
import ReactSelect from "../../Components/ReactSelect";
import { useSelector } from "react-redux";
import SearchableSelect from "../../Components/SearchableSelect";
import moment from "moment";
const EditExpenses = ({ id, locations, close }) => {
  const [validated, setValidated] = useState(false);

  // const [location, setLocation] = useState({});
  const [expenseType, setExpenseType] = useState("");
  const [expenseAmount, setExpenseAmount] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [expenseDate, setExpenseDate] = useState("");
  const [comments, setComments] = useState("");
  const [isClicked, setIsClicked] = useState(false);

  const [bank, setBank] = useState(null);
  const [cheque_number, setCheque_number] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const shop_id = user.shop_id;
  const loadBanksOptions = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + `/shops/banks/${shop_id}`, {
          withCredentials: true,
        })
        .then((banksRes) => {
          const banks = banksRes.data.map((bank) => ({
            value: bank,
            label: (
              <>
                <span style={{ fontSize: "12px" }}>
                  Bank Name: <strong className="fs-12">{bank?.bank_name}</strong>
                </span>
                <br />
                <span style={{ fontSize: "12px" }}>Account Title: {bank?.account_title}</span>
                <br />
                <span className="d-block" style={{ fontSize: "12px" }}>
                  Account Number:{bank?.account_number}
                </span>
              </>
            ),
            name: bank.bank_name,
          }));
          resolve(banks);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const handleChangeBank = (e) => {
    setBank({ value: e?.value, label: e?.name });
  };

  useEffect(() => {
    if (id !== -1) {
      axios
        .get(process.env.REACT_APP_API_URL + "/expenses/get/" + id, {
          withCredentials: true,
        })
        .then((res) => {
          // setLocation(
          //   locations.filter((loc) => loc?.value === res.data.location_id)[0]
          // );
          setExpenseType(res.data.expense_type);
          setExpenseAmount(res.data.expense_amount.toString());
          setPaymentType(res.data.payment_type);
          if (res.data.bank_id !== -1) {
            loadBanksOptions().then((bankslist) => {
              const requiredBank = bankslist.filter((bank) => bank?.value.bank_id === res.data.bank_id)[0];
              setBank({ value: requiredBank?.value, label: requiredBank?.name });
            });
          }
          setCheque_number(res.data.cheque_number === "nil" ? null : res.data.cheque_number);
          setExpenseDate(new Date(res.data.expense_date).toISOString().slice(0, 10));
          setComments(res.data.comments === "nil" ? null : res.data.comments);
        })
        .catch((err) => {
          throw new Error(err);
        });
    }
  }, [id]);

  const handleExpenseSubmit = async (e) => {
    try {
      e.preventDefault();
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.stopPropogation();
      }
      setValidated(true);
      if (expenseType.length > 0 && expenseAmount.length > 0 && Number(expenseAmount) > 0 && paymentType.length > 0 && expenseDate.length > 0 && (paymentType === "Transfer" || paymentType === "Cheque" || paymentType === "Card" ? bank?.value : true)) {
        setIsClicked(true);
        const res = await axios.patch(
          process.env.REACT_APP_API_URL + "/expenses/update",
          {
            expense_id: id,
            expense_type: expenseType,
            expense_amount: expenseAmount,
            payment_type: paymentType,
            bank_id: paymentType === "Cash" ? -1 : bank?.value.bank_id,
            cheque_number: cheque_number,
            // expense_date: moment(expenseDate).format("YYYY-MM-DD"),
            comments: comments,
          },
          { withCredentials: true }
        );
        if (res) {
          close();
        }
      }
    } catch (err) {
      setIsClicked(true);
      // throw new Error(err.response.data);
      setIsClicked(false);
    }
  };

  const handleExpenseTypeChange = (e) => {
    setExpenseType(e.target?.value);
  };
  const handleExpenseAmountChange = (e) => {
    const format = /^[0-9]*$/;
    if (format.test(e.target?.value)) {
      if (Number(e.target?.value) > 0 && e.target?.value.length < 10) {
        setExpenseAmount(e.target?.value);
      } else {
        setExpenseAmount("");
      }
    } else {
      setExpenseAmount("");
    }
  };

  const handlePaymentTypeChange = (e) => {
    setPaymentType(e.target?.value);
  };

  const handleExpenseDateChange = (e) => {
    setExpenseDate(e.target?.value);
  };
  const handleCommentsChange = (e) => {
    setComments(e.target?.value);
  };
  const handleChequeNumberChange = (e) => {
    if (e.target?.value.length < 20) {
      setCheque_number(e.target?.value);
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleExpenseSubmit} className="px-5 py-3">
      <Form.Group className="mb-3" controlId="Location">
        <SearchableSelect
          placeholder="Select location"
          label="Location"
          important
          disabled={true}
          searchable={false}
          // value={Object.keys(location).length > 0 ? location : null}
          // options={locations}
          value={Object.keys(locations).length > 0 ? locations : null}
          // handleChange={(e) => setLocation(e)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label className="mb-1">
          Expense Type <span className="text-danger">*</span>
        </Form.Label>
        <Form.Select onChange={handleExpenseTypeChange} value={expenseType}>
          <option disabled selected hidden>
            <span style={{ opacity: "0.3" }}>Select</span>
          </option>
          <option value="Rent">Rent</option>
          <option value="Purchase">Purchase</option>
          <option value="Food">Food</option>
          <option value="Electric Bills">Electric Bills</option>
          <option value="Parts">Parts</option>
          <option value="Repairing">Repairing</option>
          <option value="Delivery">Delivery</option>
          <option value="PTA Approval">PTA Approval</option>
          <option value="Tax">Tax</option>
          <option value="Salary">Salary</option>
          <option value="Others">Others</option>
        </Form.Select>
        <Form.Control hidden value={expenseType} onChange={() => {}} required />
        <Form.Control.Feedback type="invalid">Please Select Expense Type.</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label className="mb-1">
          Expense Amount <span className="text-danger">*</span>{" "}
        </Form.Label>
        <Form.Control type="number" min={1} step={1} onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()} value={expenseAmount} onChange={handleExpenseAmountChange} placeholder="e.g. 100000" required />
        <Form.Control.Feedback type="invalid">Please Select Expense Amount.</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label className="mb-1">
          Payment Type <span className="text-danger">*</span>
        </Form.Label>
        <Form.Select onChange={handlePaymentTypeChange} value={paymentType}>
          <option disabled selected hidden>
            <span style={{ opacity: "0.3" }}>Select</span>
          </option>
          <option value="Transfer">Transfer</option>
          <option value="Cash">Cash</option>
          <option value="Card">Card</option>
          <option value="Cheque">Cheque</option>
        </Form.Select>
        <Form.Control hidden value={paymentType} onChange={() => {}} required />
        <Form.Control.Feedback type="invalid">Please Select Payment Type.</Form.Control.Feedback>
      </Form.Group>
      {paymentType === "Cash" || paymentType === "" ? (
        ""
      ) : (
        <>
          {paymentType === "Cheque" ? (
            <>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1">
                  Select Bank <span className="text-danger">*</span>
                </Form.Label>
                <ReactSelect placeholder="Select" value={bank} options={loadBanksOptions} handleChange={handleChangeBank} searchable={false} />
                <Form.Control value={bank ? bank : null} onChange={() => {}} hidden required={paymentType === "Card" || paymentType === "Transfer" || paymentType === "Cheque"} />
                <Form.Control.Feedback type="invalid">Please Select Bank.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1">Cheque Number</Form.Label>
                <Form.Control type="text" step={1} value={cheque_number} onChange={handleChequeNumberChange} placeholder="Cheque Number" />
              </Form.Group>
            </>
          ) : (
            <>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1">
                  Select Bank <span className="text-danger">*</span>
                </Form.Label>
                <ReactSelect placeholder="Select" value={bank} options={loadBanksOptions} handleChange={handleChangeBank} searchable={false} />
                <Form.Control value={bank ? bank : null} onChange={() => {}} hidden required={paymentType === "Card" || paymentType === "Transfer" || paymentType === "Cheque"} />
                <Form.Control.Feedback type="invalid">Please Select Bank.</Form.Control.Feedback>
              </Form.Group>
            </>
          )}
        </>
      )}

      <Form.Group className="mb-3">
        <Form.Label className="mb-1">
          Expense date <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control type="date" value={expenseDate} onChange={handleExpenseDateChange} placeholder="Select date" required disabled={true} />
        <Form.Control.Feedback type="invalid">Please Select Expense Date.</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label className="mb-1">Comments: </Form.Label>
        <Form.Control as="textarea" value={comments} maxLength={300} onChange={handleCommentsChange} placeholder="Comments" />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Control type="button" disabled={isClicked} value="Save" className="btn btn-primary" onClick={handleExpenseSubmit} />
      </Form.Group>
    </Form>
  );
};

export default EditExpenses;
