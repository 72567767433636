import React, { useState, useRef } from "react";
import style from "./Invoice.module.css";
// import logo from '../../../assets/images/logo.png'
import customloader from "../../../assets/images/RollingLoader.gif";
import { useReactToPrint } from "react-to-print";
import { Button, Card } from "react-bootstrap";
import { useCallback } from "react";
import PrintLayout from "../../../Components/PrintLayout";
import { Table } from "react-bootstrap";
import axios from "axios";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const VendorPaymentInvoice = ({ close, id = -1 }) => {
  const { user } = useSelector((state) => state.auth);
  const logo = user.logo_path;

  const layoutRef = useRef(null);

  //print button
  const [isPrinting, setIsPrinting] = useState(false);
  const handleOnBeforeGetContent = () => {
    setIsPrinting(true);
  };

  const handleBeforePrint = () => {
    setIsPrinting(true);
  };

  const handleAfterPrint = () => {
    setIsPrinting(false);
    close();
  };

  const reactToPrintContent = useCallback(() => {
    return layoutRef.current;
  }, []);

  //react to print
  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "Payment Invoice",
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
  });

  //ERROR
  const [isError, setIsError] = useState(false);

  //data
  const [data, setData] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        if (id !== -1) {
          const res = await axios.get(process.env.REACT_APP_API_URL + "/purchase-order/payment/get-detail/" + id, { withCredentials: true });
          setData(res.data);
        }
      } catch (err) {
        setIsError(true);
      }
    };
    getData();
  }, [id]);
  return (
    <div>
      <div className="d-flex justify-content-end gap-3">
        <Button variant="secondary" onClick={close} size="lg">
          Close
        </Button>
        {data && (
          <Button variant="outline-primary" size="lg" disabled={isPrinting} onClick={!isPrinting ? handlePrint : null}>
            {isPrinting ? "Printing..." : "Print"}
          </Button>
        )}
      </div>
      {isError ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <h1>SOMTHING WENT WRONG!</h1>
        </div>
      ) : !data ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <img src={customloader} alt="customloader" width="100" height="100" />
        </div>
      ) : (
        <PrintLayout ref={layoutRef}>
          <Card className={`border-0 ${style.Card}`}>
            <div className={`d-flex flex-column text-ent p-5`}>
              <div className="d-flex justify-content-between">
                <div className="col-md-6">{logo !== null && <img src={process.env.REACT_APP_URL_S3 + "/" + logo} alt="" className={`${style.brandLogo}`} />}</div>
                <div className="col-md-6 text-end">
                  <h3>Address</h3>
                  <div>{data?.shop_address}</div>
                  {data?.shop_email !== "nil" ? <div>{data?.shop_email}</div> : ""}
                  {data?.is_contact_no_on_invoice === 1 && data?.is_alternative_no_on_invoice === 1 ? (
                    <div>
                      {data?.contact_number}
                      <br />
                      {data?.alternative_number}
                    </div>
                  ) : data?.is_contact_no_on_invoice === 1 ? (
                    data?.contact_number
                  ) : data?.is_alternative_no_on_invoice === 1 ? (
                    data?.alternative_number
                  ) : (
                    data?.owner_whatsapp_number
                  )}
                  {/* {data?.shop_number !== "nil" ? (
                    <div>{data?.shop_number}</div>
                  ) : (
                    ""
                  )} */}
                </div>
              </div>
              <div className="m-5 border-bottom">
                <h2 className="d-flex justify-content-around">
                  <span>Invoice#</span>
                  <span>{data?.invoice_number}</span>
                </h2>
              </div>

              <div className="d-flex justify-content-between px-5">
                <div className="col-md-6">
                  <div>Vendor details</div>
                  <h3>{data?.vendor_name}</h3>
                  {data?.vendor_email !== "nil" ? <div>{data?.vendor_email}</div> : ""}
                  {data?.vendor_number !== "nil" ? <div>{data?.vendor_number}</div> : ""}
                </div>
                <div className="col-md-6">
                  <div className="d-flex justify-content-end">
                    <div>
                      <div>Payment Date</div>
                      <h3>{new Date(data?.payment_date).toLocaleDateString("en-GB")}</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mx-5 mt-2">
                <Table striped bordered style={{ width: "100%", tableLayout: "fixed" }}>
                  <thead>
                    <tr>
                      <th className="text-center">
                        <h5>Purchase Detail</h5>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <strong>Purchase Order# {data?.purchase_order}</strong>
                      </td>
                    </tr>
                    {data?.purchase_detail !== "nil" ? (
                      <tr>
                        <td
                          style={{
                            wordWrap: "break-word",
                          }}
                        >
                          <strong>Comments:</strong> {data?.comments}
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                    <tr>
                      <td>
                        <strong>Purchase Amount: Rs. {Number(data?.purchase_amount).toLocaleString("en-IN")}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Purchase Date:</strong> {data?.purchase_date ? new Date(data?.purchase_date).toDateString() : "Date Not Entered!"}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="d-flex justify-content-end mx-5 mt-2">
                <div className="col-md-6">
                  <Table>
                    <tbody>
                      <tr>
                        <td>
                          <strong>Paid Amount:</strong>
                        </td>
                        <td>
                          <strong>Rs.</strong> {Number(data?.payment_amount).toLocaleString("en-IN")}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Remaining Amount Due:</strong>
                        </td>
                        <td>
                          <strong>Rs.</strong> {Number(Number(data?.purchase_amount) - Number(data?.paid_amount)).toLocaleString("en-IN")}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
              {data?.comments !== "Down Payment." ? (
                data?.comments !== "nil" ? (
                  <div className="d-flex justify-content-end mx-5 mt-2">
                    <div className="col-md-6">
                      <Table>
                        <tbody>
                          <tr>
                            <td className="border-0">
                              <strong>Comments:</strong>
                            </td>
                            <td className="border-0">{data?.comments}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}

              <hr className="border-bottom" />

              <div className="mt-1 text-center">
                <div>
                  Issue Date: <strong>{new Date().toLocaleDateString("en-GB")}</strong>
                </div>
                <p className="text-secondary mt-3 mb-2 text-center">Invoice Terms</p>
                {data?.purchase_amount - data?.paid_amount > 0 ? (
                  <div>
                    <p style={{ color: "#39414A" }}>
                      Till this day status of this Purhcase Order is
                      <span className="text-danger"> PENDING</span>. Remaining Amount is
                      <strong> Rs. {Number(data?.purchase_amount - data?.paid_amount).toLocaleString("en-IN")}</strong>
                    </p>
                  </div>
                ) : (
                  <div>
                    <p style={{ color: "#39414A" }}>
                      Till this day status of this Purhcase Order is
                      <span className="text-success"> PAID</span>.
                    </p>
                  </div>
                )}
                {data?.vendor_invoice_terms !== "nil" && <p className={`my-0 text-center ${style.terms}`}>* {data?.vendor_invoice_terms}</p>}
              </div>
            </div>
          </Card>
        </PrintLayout>
      )}
    </div>
  );
};

export default VendorPaymentInvoice;
