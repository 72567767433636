import React, { useCallback, useEffect, useRef } from "react";
import axios from "axios";
import style1 from "../../styles/InvoiceCard.module.css";
import style from "../../styles/Invoice.module.css";
import { Col, Row, Table, Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { forwardRef } from "react";
import { useSelector } from "react-redux";
import "../../styles/printLayout.css";
import Loading from "../../Components/Loading/Loading";

const QuotationInvoice = forwardRef((props, ref) => {
  const { printId, ...restProps } = props;
  const { user } = useSelector((state) => state.auth);
  const shop_id = user.shop_id;
  const logo = user.logo_path;
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [items, setItems] = useState([]);
  const [isIdUndefined, setIsIdUndefined] = useState(false);
  const [totalDiscount, setTotalDiscount] = useState();
  const [isloading, setisLoading] = useState(true);

  useEffect(() => {
    const pageTimeout = setTimeout(() => {
      try {
        if (id || props.printId) {
          setIsIdUndefined(false);
          const getData = async () => {
            const res = await axios.get(process.env.REACT_APP_API_URL + `/quotation/get/${id ? id : printId}`, { withCredentials: true });
            if (res) {
              let arr = [];
              const products = res.data.items.map((i) => ({
                title: i.item_name,
                cartQuantity: i.qty_ordered,
                price: i.price,
                discount: i.discount,
              }));

              const miscProducts = res.data.misc_items.map((i) => ({
                title: i.name,
                cartQuantity: i.qty_ordered,
                price: i.price,
                discount: i.discount,
              }));

              arr.push(...products);
              arr.push(...miscProducts);

              setData(res.data);

              setItems(arr);

              setisLoading(false);
            }
          };
          getData();
        } else {
          setIsIdUndefined(true);
        }
      } catch (err) {
        setisLoading(false);
        setIsIdUndefined(true);
      }
    }, 0);
    return () => {
      clearTimeout(pageTimeout);
    };
  }, [id, printId, shop_id]);

  useEffect(() => {
    const sum = items?.reduce((accumulator, object) => {
      return Number(accumulator) + Number(object.discount);
    }, 0);
    setTotalDiscount(sum);
  }, [items]);
  return (
    <>
      {isloading ? (
        <Loading />
      ) : items?.length === 0 ? (
        <div style={{ height: "80vh" }} className="d-flex justify-content-center align-items-center">
          <h3>No data exists for this invoice</h3>
        </div>
      ) : (
        <Card className={`border-0 ${style1.invoiceCard} ${style1.formCard} mb-5`}>
          <div className={"p-5 laser-invoice"} ref={ref} {...restProps}>
            <div className={`d-flex ${logo !== null ? "justify-content-between" : "justify-content-end"} ${style.logoadd}`}>
              {logo !== null && <img src={process.env.REACT_APP_URL_S3 + "/" + logo} alt="" className={`${style1.logoimg} ${style.logo}`} />}
              <div className={`d-flex flex-column text-end mb-4 ${style.address}`}>
                <h5 style={{ color: "#39414A", marginBottom: "0rem" }}>Address</h5>
                <span style={{ color: "#39414A", marginBottom: "0rem" }} className={`${style.addresstxt}`}>
                  {data?.shop_address}
                </span>
                <span style={{ color: "#39414A", marginBottom: "0rem" }} className={`${style.addresstxt}`}>
                  Phone:{" "}
                  {data?.is_contact_no_on_invoice === 1 && data?.is_alternative_no_on_invoice === 1 ? (
                    <>
                      {data?.contact_number}
                      <br />
                      {data?.alternative_number}
                    </>
                  ) : data?.is_contact_no_on_invoice === 1 ? (
                    data?.contact_number
                  ) : data?.is_alternative_no_on_invoice === 1 ? (
                    data?.alternative_number
                  ) : (
                    data?.owner_whatsapp_number
                  )}
                </span>
                <span style={{ color: "#39414A", marginBottom: "0rem" }} className={`${style.addresstxt}`}></span>

                <span style={{ color: "#39414A" }} className={`${style.addresstxt}`}>
                  {data?.owner_email}
                </span>
                <span style={{ color: "#39414A" }} className={`${style.addresstxt} text-capitalize`}>
                  {user?.user_full_name}
                </span>
              </div>
            </div>
            <h1 className={`mb-3 ${style.invoice}`}>Quote</h1>
            <Row>
              <Col xs sm="5" md="5" lg="5" className="px-0 ps-3">
                <p className={`${style.invoiceterms}`} style={{ textAlign: "inherit" }}>
                  Invoice To
                </p>
                <span
                  className="text-capitalize"
                  style={{
                    color: "#39414A",
                    marginBottom: "0rem",
                    fontSize: "18px",
                    fontWeight: "700",
                  }}
                >
                  {data?.customer_name}
                </span>
                <div className={`mb-2 ${style.phn} px-0`}>
                  <p className={`${style.emailphone}`}>
                    <strong>Phone:</strong> {data?.customer_number}
                  </p>
                  {data?.customer_email && data?.customer_email !== "nil" && data?.customer_email !== "" ? (
                    <p className={`${style.emailphone}`}>
                      <strong>Email:</strong> {data?.customer_email}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </Col>
              <Col xs sm="4" md="4" lg="4" className={`${style.invoicenum}`}>
                <p className={`${style.invoiceterms}`} style={{ textAlign: "inherit" }}>
                  Quote Number
                </p>
                <h5 className={`${style.invoicetxt} fw-2`}>{data?.sales_order_number}</h5>
              </Col>
              <Col xs sm="3" md="3" lg="3" className={`${style.invoicenum}`}>
                <p className={`${style.invoiceterms}`} style={{ textAlign: "inherit" }}>
                  Quote Date
                </p>
                <h5 className={`${style.invoicetxt}`}>{new Date(data?.invoice_date).toLocaleDateString("en-GB")}</h5>
              </Col>
            </Row>
            <Table style={{ border: "1px solid #DEDFE4", tableLayout: "fixed" }} className={`mb-4 mt-3 ${style.invoiceTable}`}>
              <thead style={{ background: "#F5F6FA" }}>
                <th className={`${style.tabledescription}`}>Products</th>
                <th className={`${style.tabletxt}`}>Unit price</th>
                <th className={`${style.tabletxt}`}>QTY</th>
                <th className={`${style.tabletxt}`}>Discount</th>
                <th className={`${style.tabletxt}`}>Total</th>
              </thead>
              {items?.map((item, index) => (
                <tr key={index}>
                  <td className={`${style.tabledescription} d-flex flex-column`}>
                    {item?.title}
                    {item?.description && <span style={{ color: "#686E76", fontSize: "13px" }}>{item?.description}</span>}
                  </td>
                  <td className={`${style.tabletxt}`}>{Number(item?.price).toLocaleString("en-IN")}</td>
                  <td className={`${style.tabletxt}`}>{item?.cartQuantity}</td>
                  <td className={`${style.tabletxt}`}>{item?.discount}</td>
                  <td className={`${style.tabletxt}`}>{Number(Number(item.cartQuantity * item.price) - Number(item.discount)).toLocaleString("en-IN")}</td>
                </tr>
              ))}
            </Table>
            <Row>
              <Col xs="2" md="6" lg="6"></Col>
              <Col xs="5" md="3" lg="3">
                <span className={`${style.tabletxt}`}> Subtotal </span>
              </Col>
              <Col xs="5" md="3" lg="3" className="text-end">
                <span className={`${style.tabletxt}`}> {Number(data?.base_subtotal).toLocaleString("en-IN")} </span>
              </Col>
            </Row>
            <Row>
              <Col xs="2" md="6" lg="6"></Col>
              <Col xs="5" md="3" lg="3">
                <span className={`${style.tabletxt}`}> Discount </span>
              </Col>
              <Col xs="5" md="3" lg="3" className="text-end">
                <span className={` ${style.tabletxt}`}> {Number(totalDiscount).toLocaleString("en-IN")} </span>
              </Col>
            </Row>
            <Row>
              <Col xs="2" md="6" lg="6"></Col>
              <Col xs="5" md="3" lg="3">
                <span className={`${style.tabletxt}`}> Shipping </span>
              </Col>
              <Col xs="5" md="3" lg="3" className="text-end">
                <span className={`  ${style.tabletxt}`}>{Number(data?.base_shipping_amount).toLocaleString("en-IN")} </span>
              </Col>
            </Row>
            <Row className="p-0">
              <Col xs="2" md="6" lg="6"></Col>
              <Col xs="10" md="10" lg="6" className="text-end">
                <hr className="my-1" />
              </Col>
            </Row>
            <Row>
              <Col xs="2" md="6" lg="6"></Col>
              <Col xs="5" md="3" lg="3">
                <span className={`${style.tabletxt} fw-bold`}> Grand Total </span>
              </Col>
              <Col xs="5" md="3" lg="3" className="text-end">
                <span className={`fw-bold  ${style.tabletxt}`}> {Number(data?.base_grand_total).toLocaleString("en-IN")} </span>
              </Col>
            </Row>
            <Row style={{ display: "contents" }}>
              <div className="mb-4" style={{ borderTop: "1px solid #DEDFE4" }}></div>
            </Row>
            <p className={`${style.invoiceterms} m-0`}>Invoice terms</p>

            <p className={`my-0 text-center terms ${style.terms}`}>*{`This quote is valid until ${new Date(data?.valid_till).toLocaleDateString()}. After this date, prices and terms are subject to change without notice.`}</p>
          </div>
        </Card>
      )}
    </>
  );
});

export default QuotationInvoice;
