import React, { forwardRef, useEffect } from "react";
// import logo from './../../../assets/images/logo.png'
import style1 from "./../../../styles/InvoiceCard.module.css";
import style from "./../../../styles/Invoice.module.css";
import { Col, Row, Table, Card } from "react-bootstrap";
import { useState } from "react";
import { useSelector } from "react-redux";
import "./../../../styles/printLayout.css";

const Invoice = forwardRef((props, ref) => {
  const { user } = useSelector((state) => state.auth);
  const logo = user.logo_path;
  const data = props.data;
  const items = props.items;
  const [remainingAmount, setRemainingAmount] = useState();
  const [totalDiscount, setTotalDiscount] = useState();
  useEffect(() => {
    const amount = Number(data.base_grand_total) - Number(data.total_received);
    setRemainingAmount(amount);
  }, [data]);

  useEffect(() => {
    const sum = items?.reduce((accumulator, object) => {
      return Number(accumulator) + Number(object.discount);
    }, 0);
    setTotalDiscount(sum);
  }, [items]);

  return (
    <>
      <Card className={`border-0 ${style1.invoiceCard} ${style1.formCard}`}>
        <div ref={ref} {...props} className="laser-invoice">
          <div className="p-5">
            <div className={`d-flex ${logo !== null ? "justify-content-between" : "justify-content-end"} ${style.logoadd}`}>
              {logo !== null && <img src={process.env.REACT_APP_URL_S3 + "/" + logo} alt="" className={`${style1.logoimg} ${style.logo}`} />}
              <div className={`d-flex flex-column text-end mb-4 ${style.address}`}>
                <h5 style={{ color: "#39414A", marginBottom: "0rem" }}>Address</h5>
                <span style={{ color: "#39414A", marginBottom: "0rem" }} className={`${style.addresstxt}`}>
                  {data.shop_address}
                </span>
                <span style={{ color: "#39414A", marginBottom: "0rem" }} className={`${style.addresstxt}`}>
                  {/* Phone: {data.owner_whatsapp_number} */}
                  Phone:{" "}
                  {data?.is_contact_no_on_invoice === 1 && data?.is_alternative_no_on_invoice === 1 ? (
                    <>
                      {data?.contact_number}
                      <br />
                      {data?.alternative_number}
                    </>
                  ) : data?.is_contact_no_on_invoice === 1 ? (
                    data?.contact_number
                  ) : data?.is_alternative_no_on_invoice === 1 ? (
                    data?.alternative_number
                  ) : (
                    data?.owner_whatsapp_number
                  )}
                </span>
                <span style={{ color: "#39414A" }} className={`${style.addresstxt}`}>
                  {data.owner_email}
                </span>
                <span style={{ color: "#39414A" }} className={`${style.addresstxt} text-capitalize`}>
                  {user?.user_full_name}
                </span>
              </div>
            </div>
            <h1 className={`mb-3 invoiceHeading ${style.invoice}`}>Invoice</h1>
            <Row>
              <Col xs sm="5" md="5" lg="5" className="px-0 ps-3">
                <p className={`${style.invoiceterms}`} style={{ textAlign: "inherit" }}>
                  Customer details
                </p>
                {data.customer_name === "nil" ? (
                  <span
                    className="text-capitalize"
                    style={{
                      color: "#39414A",
                      marginBottom: "0rem",
                      fontSize: "18px",
                      fontWeight: "700",
                    }}
                  >
                    Walk-in Customer
                  </span>
                ) : (
                  <>
                    <span
                      className="text-capitalize"
                      style={{
                        color: "#39414A",
                        marginBottom: "0rem",
                        fontSize: "18px",
                        fontWeight: "700",
                      }}
                    >
                      {data.customer_name}
                    </span>
                    <div className={`mb-2 ${style.phn} px-0`}>
                      <p className={`${style.emailphone}`}>
                        <strong>Phone:</strong> {data.customer_number}
                      </p>
                      {data?.customer_email && data?.customer_email !== "nil" && data?.customer_email !== "" ? (
                        <p className={`${style.emailphone}`}>
                          <strong>Email:</strong> {data.customer_email}
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                )}
              </Col>
              <Col xs sm="4" md="4" lg="4" className={`${style.invoicenum}`}>
                <p className={`${style.invoiceterms}`} style={{ textAlign: "inherit" }}>
                  Sales Order number
                </p>
                <h5 className={`${style.invoicetxt} fw-2`}>{data.sales_order_number}</h5>
              </Col>
              <Col xs sm="3" md="3" lg="3" className={`${style.invoicenum}`}>
                <p className={`${style.invoiceterms}`} style={{ textAlign: "inherit" }}>
                  Issue date
                </p>
                <h5 className={`${style.invoicetxt}`}>{new Date().toLocaleDateString("en-GB")}</h5>
              </Col>
            </Row>
            <div>
              <Table style={{ border: "1px solid #DEDFE4", tableLayout: "fixed" }} className={`mb-4 mt-3 ${style.invoiceTable}`}>
                <thead style={{ background: "#F5F6FA" }}>
                  <th className={`${style.tabledescription}`}>Products</th>
                  <th className={`${style.tabletxt}`}>Unit price</th>
                  <th className={`${style.tabletxt}`}>QTY</th>
                  <th className={`${style.tabletxt}`}>Discount</th>
                  <th className={`${style.tabletxt}`}>Total</th>
                </thead>
                {items?.map((cartItem, index) => (
                  <tr key={index}>
                    <td className={`${style.tabledescription} d-flex flex-column`}>
                      {cartItem.title}
                      <span>{cartItem.description}</span>
                    </td>
                    <td className={`${style.tabletxt}`}>{Number(cartItem.price).toLocaleString("en-IN")}</td>
                    <td className={`${style.tabletxt}`}>{cartItem.cartQuantity}</td>
                    <td className={`${style.tabletxt}`}>{cartItem.discount}</td>
                    <td className={`${style.tabletxt}`}>{Number(Number(cartItem.cartQuantity * cartItem.price) - Number(cartItem.discount)).toLocaleString("en-IN")}</td>
                  </tr>
                ))}
              </Table>
            </div>
            <Row>
              <Col xs="4" sm="4" md="6" lg="6"></Col>
              <Col xs="4" sm="4" md="3" lg="3">
                <span className={`${style.tabletxt}`}> Subtotal </span>
              </Col>
              <Col xs="4" sm="4" md="3" lg="3" className="text-end">
                <span className={`${style.tabletxt}`}> {Number(data?.base_subtotal).toLocaleString("en-IN")} </span>
              </Col>
            </Row>
            <Row>
              <Col xs="4" sm="4" md="6" lg="6"></Col>
              <Col xs="4" sm="4" md="3" lg="3">
                <span className={`${style.tabletxt}`}> Discount </span>
              </Col>
              <Col xs="4" sm="4" md="3" lg="3" className="text-end">
                <span className={` ${style.tabletxt}`}> {Number(totalDiscount).toLocaleString("en-IN")} </span>
              </Col>
            </Row>
            {/* <Row>
              <Col xs="4" sm="4" md="6" lg="6"></Col>
              <Col xs="4" sm="4" md="3" lg="3">
                <span className={`${style.tabletxt}`}> Total </span>
              </Col>
              <Col xs="4" sm="4" md="3" lg="3" className="text-end">
                <span className={`${style.tabletxt}`}>
                  {" "}
                  {Number(
                    data?.base_subtotal - Number(totalDiscount)
                  ).toLocaleString("en-IN")}{" "}
                </span>
              </Col>
            </Row> */}
            <Row>
              <Col xs="4" sm="4" md="6" lg="6"></Col>
              <Col xs="4" sm="4" md="3" lg="3">
                <span className={`${style.tabletxt}`}> Shipping </span>
              </Col>
              <Col xs="4" sm="4" md="3" lg="3" className="text-end">
                <span className={`  ${style.tabletxt}`}> {Number(data?.base_shipping_amount).toLocaleString("en-IN")} </span>
              </Col>
            </Row>

            <Row>
              <Col xs="4" sm="4" md="6" lg="6"></Col>
              <Col xs="4" sm="4" md="3" lg="3">
                <span className={`${style.tabletxt} fw-bold`}> Grand Total </span>
              </Col>

              <Col xs="4" sm="4" md="3" lg="3" className="text-end">
                <span className={`fw-bold  ${style.tabletxt}`}> {Number(data?.base_grand_total).toLocaleString("en-IN")} </span>
              </Col>
            </Row>
            <Row className="p-0">
              <Col xs="4" md="6" lg="6"></Col>

              <Col xs="8" md="6" lg="6">
                <hr className="my-1" />
              </Col>
            </Row>
            <Row>
              <Col xs="4" sm="4" md="6" lg="6"></Col>
              <Col xs="4" sm="4" md="3" lg="3">
                <span className={`${style.tabletxt}`}> Total Received</span>
              </Col>

              <Col xs="4" sm="4" md="3" lg="3" className="text-end">
                <span className={` ${style.tabletxt}`}> {Number(data?.total_received).toLocaleString("en-IN")} </span>
              </Col>
            </Row>

            {remainingAmount > 0 && (
              <Row>
                <Col xs="4" sm="4" md="6" lg="6"></Col>
                <Col xs="4" sm="4" md="3" lg="3">
                  <span className={`${style.tabletxt}`}> Total Due</span>
                </Col>

                <Col xs="4" sm="4" md="3" lg="3" className="text-end">
                  <span className={` ${style.tabletxt}`}> {Number(remainingAmount).toLocaleString("en-IN")} </span>
                </Col>
              </Row>
            )}
            <Row style={{ display: "contents" }}>
              <div className="mb-2" style={{ borderTop: "1px solid #DEDFE4" }}></div>
            </Row>
            <p className={`${style.invoiceterms} m-0`}>Invoice terms</p>
            {remainingAmount === 0 ? (
              <>
                <p className="m-0" style={{ color: "#39414A", textAlign: "center" }}>
                  {" "}
                  The Buyer has <b>PAID</b> the amount.
                </p>
              </>
            ) : (
              <>
                <p className="m-0" style={{ color: "#39414A", textAlign: "center" }}>
                  {" "}
                  The Buyer still needs to pay <b>{Number(remainingAmount).toLocaleString("en-IN")}</b> to clear the invoice.
                </p>
              </>
            )}
            {data?.invoice_terms !== "nil" && <p className={`my-0 text-center terms ${style.terms}`}>* {data?.invoice_terms}</p>}
            <p className={`${style.invoiceterms} mt-2`}>Comments</p>
            <p className="text-capitalize text-center my-0">{data?.sales_order_comment === "" ? "-" : data?.sales_order_comment}</p>
          </div>
        </div>
      </Card>
    </>
  );
});
export default Invoice;
