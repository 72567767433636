import React from "react";
import logo2 from "../../assets/images/logo2.png";
import logo3 from "../../assets/images/logo3.png";
import moment from "moment";
const Invoice = ({ invoiceData }) => {
  return (
    <>
      <div className="d-flex justify-content-center align-items-center">
        <img style={{ pointerEvents: "none" }} className="me-2" src={logo3} alt="logo" />
        <img style={{ pointerEvents: "none" }} src={logo2} alt="logo" />
      </div>
      <div className="d-flex justify-content-center my-3" style={{ fontSize: "22px", fontWeight: "bold", backgroundColor: "#e4e4e4" }}>
        <span>INVOICE</span>
      </div>
      <div className="d-flex justify-content-between align-items-stretch my-3">
        <div className="px-2 py-3 border border-dark" style={{ width: "40%" }}>
          <div>
            <strong>Billing Details:</strong>
          </div>
          <div>Customer Name: {invoiceData?.billing_details?.customer_name}</div>
          <div>Customer Phone: {invoiceData?.billing_details?.customer_phone}</div>
          <div>Customer Address: {invoiceData?.billing_details?.customer_address}</div>
        </div>
        <div className="px-2 py-3 border border-dark text-wrap" style={{ width: "40%" }}>
          <div>
            <strong>Seller Details:</strong>
          </div>
          <div>Shop Name: {invoiceData?.seller_details?.shop_name}</div>
          <div>Shop Address: {invoiceData?.seller_details?.location_address}</div>
          <div>Location Province: {invoiceData?.seller_details?.location_province}</div>
          <div>Location city: {invoiceData?.seller_details?.location_city}</div>
        </div>
      </div>
      <div className="d-flex justify-content-around align-items-stretch border border-dark mb-4">
        <div className="px-2 border-end border-dark">Order Number: {invoiceData?.order_number}</div>
        <div className="px-2 border-end border-dark">Payment Mode: Cash on delivery</div>
        <div className="px-2">Order Date: {invoiceData?.invoice_date ? moment(invoiceData?.invoice_date).format("DD/MM/YYYY") : moment().format("DD/MM/YYYY")}</div>
      </div>
      <div className="table-responsive">
        <table className="w-100 mt-3 border border-dark ">
          <thead>
            <tr>
              <th className="border border-dark text-center" colSpan="7">
                Your Ordered Item(s)
              </th>
            </tr>
            <tr className="border border-dark text-center">
              <th className="border-end border-dark">S/N</th>
              <th className="border-end border-dark">Product Name</th>
              <th className="border-end border-dark">Item SKU</th>
              <th className="border-end border-dark">Quantity</th>
              <th className="border-end border-dark">Price</th>
              <th className="border-end border-dark">Discount</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {invoiceData?.items?.map((item, index) => (
              <tr key={index} className="border border-dark text-center">
                <td className="border-end border-dark">{index + 1}</td>
                <td className="border-end border-dark">{item?.item_name}</td>
                <td className="border-end border-dark">{item?.item_sku}</td>
                <td className="border-end border-dark">{item?.quantity}</td>
                <td className="border-end border-dark">{item?.unit_price}</td>
                <td className="border-end border-dark">{item?.discount?.toFixed(0)}</td>
                <td>{item?.total_price}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr className="border border-dark text-center">
              <th className="border-end border-dark"></th>
              <th className="border-end border-dark" colSpan="2">
                Total: {invoiceData?.totals?.total_unit_price?.toFixed(0)}
              </th>
              <th className="border-end border-dark">{invoiceData?.items?.reduce((acc, curr) => acc + curr.quantity, 0)}</th>
              <th className="border-end border-dark">{invoiceData?.items?.reduce((acc, curr) => acc + curr.unit_price, 0)}</th>
              <th className="border-end border-dark">{invoiceData?.items?.reduce((acc, curr) => acc + curr.discount, 0)}</th>
              <th>{invoiceData?.items?.reduce((acc, curr) => acc + curr.total_price, 0)}</th>
            </tr>
          </tfoot>
        </table>
      </div>
      <div className="w-100 mt-2 d-flex justify-content-end mt-3">
        <div
        // className={`${style?.invoiceFooter}}`}
        >
          <table className="w-100">
            <tbody>
              {invoiceData?.voucher_details?.voucher_code !== null && (
                <>
                  <tr>
                    <th>Voucher Code</th>
                    <td></td>
                    <td className="px-3">{invoiceData?.voucher_details?.voucher_code}</td>
                  </tr>
                  <tr>
                    <th>Voucher Discount</th>
                    <td>Rs:</td>
                    <td className="px-3">{invoiceData?.voucher_details?.voucher_discount}</td>
                  </tr>
                </>
              )}
              <tr>
                <th>Total Price</th>
                <td>Rs:</td>
                <td className="px-3">{invoiceData?.totals?.total_unit_price}</td>
              </tr>
              <tr>
                <th>Total Shipping</th>
                <td>Rs:</td>
                <td className="px-3">{invoiceData?.totals?.delivery_charges}</td>
              </tr>
              <tr>
                <th>Total </th>
                <td>Rs:</td>
                <td className="border-top border-bottom border-dark px-3">{invoiceData?.totals?.total}</td>
              </tr>
              <tr>
                <th>Total Payable Amount</th>
                <td>Rs:</td>
                <td className="px-3">{invoiceData?.totals?.total_payable_amount}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="w-100 my-5 text-center">**This is a computer generated invoice. No signature is required**</div>
    </>
  );
};

export default Invoice;
