import React, { useState, useEffect } from "react";
import axios from "axios";
import CustomizeTableview from "../../TableViews/CustomizeTableview";
import Sttyle from "../../../styles/ProgressBar.module.css";
import { requestDateTime } from "../../../Features/dateTime";

function SalesTrendReportTab({ filter, shopId, fromDate, toDate, location, brand, category, customer, salesperson, paymentType, product, open }) {
  let [items, setItems] = useState([]);
  let [highestColumn, setHighestColumn] = useState({});
  let [isLoading, setIsLoading] = useState(true);
  const queryString = new URLSearchParams({
    ...(location?.value ? { location: location?.value } : {}),
    ...(product?.value ? { item: product?.value } : {}),
    ...(filter ? filter : {}),
    ...(fromDate && toDate ? { "date[from]": requestDateTime(fromDate).toISOString(), "date[to]": requestDateTime(toDate).toISOString() } : {}),
    ...(brand?.value ? { brand: brand?.value } : {}),
    ...(category?.value ? { category: category?.value } : {}),
    ...(customer?.value ? { customer: customer?.value } : {}),
    ...(salesperson?.value ? { salesperson: salesperson?.value } : {}),
    ...(paymentType?.value ? { paymentType: paymentType?.value } : {}),
  });
  useEffect(() => {
    try {
      const getData = async () => {
        const res = await axios.get(process.env.REACT_APP_API_URL + `/reports/sales-report/${shopId}${queryString?.toString()?.length ? `?${queryString.toString()}` : ""}`, {
          withCredentials: true,
        });
        if (res.data.code === 200) {
          const data = res.data.success.data.salesReport;
          const highest = Object.entries(data).reduce(
            (dayObj, [dayKey, dayValue]) => {
              dayObj = Object.entries(dayValue).reduce((timeObj, [timeKey, timeValue]) => {
                if (timeKey === "total") {
                  return timeObj;
                } else {
                  if (timeObj.value < timeValue) {
                    return {
                      days: new Array(dayKey),
                      times: new Array(timeKey),
                      value: timeValue,
                    };
                  } else if (timeObj.value === timeValue && timeObj.value !== 0) {
                    return {
                      days: timeObj.days.some((val) => val === dayKey) ? timeObj.days : [...timeObj.days, dayKey],
                      times: timeObj.times.some((val) => val === timeKey) ? timeObj.times : [...timeObj.times, timeKey],
                      value: timeValue,
                    };
                  } else {
                    return timeObj;
                  }
                }
              }, dayObj);
              return dayObj;
            },
            {
              days: [],
              times: [],
              value: 0,
            }
          );
          setHighestColumn(highest);
          setItems(data);
        } else {
          setItems([]);
        }
        setIsLoading(false);
      };
      if (location?.value) {
        setIsLoading(true);
        getData();
      }
    } catch (error) {
      setItems([]);
      setIsLoading(false);
      throw new Error(error.response.data);
    }
  }, [filter, shopId, fromDate, toDate, location, brand, category, customer, paymentType, salesperson, product]);

  return (
    <>
      {isLoading ? (
        <div className={Sttyle.statusBar}></div>
      ) : (
        <>
          <table className="table table-borderless border-start mb-0">
            <thead className="">
              <tr className="border-bottom">
                <th scope="col">#</th>
                <th scope="col" className="strippedColumn text-center" style={highestColumn?.days?.some((val) => val === "MON") ? { color: "#a93bff" } : {}}>
                  MON
                </th>
                <th className="text-center" scope="col" style={highestColumn?.days?.some((val) => val === "TUE") ? { color: "#a93bff" } : {}}>
                  TUE
                </th>
                <th scope="col" className="strippedColumn text-center" style={highestColumn?.days?.some((val) => val === "WED") ? { color: "#a93bff" } : {}}>
                  WED
                </th>
                <th scope="col" className="text-center" style={highestColumn?.days?.some((val) => val === "THU") ? { color: "#a93bff" } : {}}>
                  THU
                </th>
                <th scope="col" className="strippedColumn text-center" style={highestColumn?.days?.some((val) => val === "FRI") ? { color: "#a93bff" } : {}}>
                  FRI
                </th>
                <th scope="col" className=" text-center" style={highestColumn?.days?.some((val) => val === "SAT") ? { color: "#a93bff" } : {}}>
                  SAT
                </th>
                <th scope="col" className="strippedColumn text-center" style={highestColumn?.days?.some((val) => val === "SUN") ? { color: "#a93bff" } : {}}>
                  SUN
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-bottom">
                <th scope="row" style={highestColumn?.times?.some((val) => val === "6 AM - 12 PM") ? { color: "#a93bff" } : {}}>
                  6 AM - 12 PM
                </th>
                <td className="strippedColumn text-center">{items?.MON["6 AM - 12 PM"]}</td>
                <td className="text-center">{items?.TUE["6 AM - 12 PM"]}</td>
                <td className="strippedColumn text-center">{items?.WED["6 AM - 12 PM"]}</td>
                <td className="text-center">{items?.THU["6 AM - 12 PM"]}</td>
                <td className="strippedColumn text-center">{items?.FRI["6 AM - 12 PM"]}</td>
                <td className="text-center">{items?.SAT["6 AM - 12 PM"]}</td>
                <td className="strippedColumn text-center">{items?.SUN["6 AM - 12 PM"]}</td>
              </tr>
              <tr className="border-bottom">
                <th scope="row" style={highestColumn?.times?.some((val) => val === "12 PM - 6 PM") ? { color: "#a93bff" } : {}}>
                  12 PM - 6 PM
                </th>
                <td className="strippedColumn text-center">{items?.MON["12 PM - 6 PM"]}</td>
                <td className="text-center">{items?.TUE["12 PM - 6 PM"]}</td>
                <td className="strippedColumn text-center">{items?.WED["12 PM - 6 PM"]}</td>
                <td className="text-center">{items?.THU["12 PM - 6 PM"]}</td>
                <td className="strippedColumn text-center">{items?.FRI["12 PM - 6 PM"]}</td>
                <td className="text-center">{items?.SAT["12 PM - 6 PM"]}</td>
                <td className="strippedColumn text-center">{items?.SUN["12 PM - 6 PM"]}</td>
              </tr>
              <tr className="border-bottom ">
                <th scope="row" style={highestColumn?.times?.some((val) => val === "6 PM - 12 PM") ? { color: "#a93bff" } : {}}>
                  6 PM - 12 PM
                </th>
                <td className="strippedColumn text-center">{items?.MON["6 PM - 12 PM"]}</td>
                <td className="text-center">{items?.TUE["6 PM - 12 PM"]}</td>
                <td className="strippedColumn text-center">{items?.WED["6 PM - 12 PM"]}</td>
                <td className="text-center">{items?.THU["6 PM - 12 PM"]}</td>
                <td className="strippedColumn text-center">{items?.FRI["6 PM - 12 PM"]}</td>
                <td className="text-center">{items?.SAT["6 PM - 12 PM"]}</td>
                <td className="strippedColumn text-center">{items?.SUN["6 PM - 12 PM"]}</td>
              </tr>
              <tr className="border-bottom border-secondary">
                <th scope="row" style={highestColumn?.times?.some((val) => val === "12 AM - 6 AM") ? { color: "#a93bff" } : {}}>
                  12 AM - 6 AM
                </th>
                <td className="strippedColumn text-center">{items?.MON["12 AM - 6 AM"]}</td>
                <td className="text-center">{items?.TUE["12 AM - 6 AM"]}</td>
                <td className="strippedColumn text-center">{items?.WED["12 AM - 6 AM"]}</td>
                <td className="text-center">{items?.THU["12 AM - 6 AM"]}</td>
                <td className="strippedColumn text-center">{items?.FRI["12 AM - 6 AM"]}</td>
                <td className="text-center">{items?.SAT["12 AM - 6 AM"]}</td>
                <td className="strippedColumn text-center">{items?.SUN["12 AM - 6 AM"]}</td>
              </tr>
              <tr className="border-bottom border-secondary">
                <th scope="row">Total</th>
                <td className="strippedColumn text-center">{items?.MON["total"]}</td>
                <td className="text-center">{items?.TUE["total"]}</td>
                <td className="strippedColumn text-center">{items?.WED["total"]}</td>
                <td className="text-center">{items?.THU["total"]}</td>
                <td className="strippedColumn text-center">{items?.FRI["total"]}</td>
                <td className="text-center">{items?.SAT["total"]}</td>
                <td className="strippedColumn text-center">{items?.SUN["total"]}</td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </>
  );
}

export default SalesTrendReportTab;
