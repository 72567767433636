import axios from "axios";
import React, { useEffect, useState } from "react";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import { useSelector } from "react-redux";
import MobileSearchAcoordian from "../../Components/MobileSearchAcoordian";
import { Button, Col, Container, Row } from "react-bootstrap";
import SearchableSelect from "../../Components/SearchableSelect";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import LoaderPage from "../../Components/loaders/loaderPage";
import NoResult from "../../Components/NoResult";
import PaginationBar from "../../Components/PaginationBar";
import filterStyle from "../../styles/filtersButtons.module.css";
import filterr from "../../assets/svg/filter.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import FeaturedItemsRequestModal from "../../Components/Listings/FeaturedItems/FeaturedItemsRequestModal";
import Csv from "../../Components/csv/Csv";
import { toast } from "react-toastify";
import PaymentModal from "../../Components/PaymentModal";
import { dateTimeForInvoice } from "../../Features/dateTime";

const FeaturedListings = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const shop_id = user.shop_id;
  const shopName = user.shop_name;
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [toogleMobileSearch, setToogleMobileSearch] = useState(false);
  const [toggle, refresh] = useState(false);
  const [statusSearch, setStatusSearch] = useState({ value: null, label: "All" });
  const [featuredRequests, setFeaturedRequests] = useState([]);
  const [totalFeaturedRequest, setTotalFeaturedRequests] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [orderBy, setOrderBy] = useState({ bookingDatetime: "DESC" });
  const [bookingData, setBookingData] = useState({});
  const [requestData, setRequestData] = useState(null);
  const [featuredListingsModalShow, setFeaturedListingsModalShow] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);

  const handleAcoordianCLose = () => {
    setToogleMobileSearch(false);
    setStatusSearch(null);
  };
  const filter = () => {
    setToogleMobileSearch(false);
  };

  const handleAcoordianOpen = (value) => {
    setToogleMobileSearch(value);
  };

  const searchStatus = (e) => {
    setStatusSearch({ value: e.value, label: e.label });
  };

  const searchStatusMobile = (e) => {
    setStatusSearch({ value: e.target.id, label: e.target.value });
  };

  const resetFilterHandle = (e) => {
    setStatusSearch({ value: null, label: "All" });
  };

  const SearchData = [
    {
      name: "Select Status",
      handleChange: searchStatusMobile,
      value: statusSearch,
      filter: [
        { value: null, label: "All" },
        { value: "Pending Confirmation", label: "Pending Confirmation" },
        { value: "Pending Payment", label: "Pending Payment" },
        { value: "Approved", label: "Approved" },
        { value: "Rejected", label: "Rejected" },
        { value: "Concluded", label: "Concluded" },
      ],
    },
  ];

  const changePage = (page) => {
    setCurrentPage(page);
  };

  //searchParams
  useEffect(() => {
    if (searchParams.get("page")) {
      setCurrentPage(Number(searchParams.get("page")));
    }
  }, [currentPage, toggle, searchParams]);

  function fetchFeaturedListings() {
    const [orderByObject] = Object.entries(orderBy);
    const query = new URLSearchParams({
      ...(statusSearch?.label === "All" ? {} : { status: statusSearch?.label }),
      orderBy: `${orderByObject[0]}:${orderByObject[1]}`,
      page: currentPage,
      size: sizeOfPages,
    }).toString();
    axios
      .get(process.env.REACT_APP_API_URL + `/listings/featured-booking?${query}`, { withCredentials: true })
      .then(({ data }) => {
        setIsLoading(false);
        if (data.success.pagination.totalPages === 0) {
          if (searchParams.get("page")) {
            setNoOfPages(0);
            navigate("/listings/featured-listings?page=0");
          }
        } else {
          setTotalFeaturedRequests(data.success.pagination.totalCount);
          setNoOfPages(data.success.pagination.totalPages);
          if (currentPage > data.success.pagination.totalPages) {
            setCurrentPage(data.success.pagination.totalPages);
            navigate("/listings/featured-listings?page=" + data.success.pagination.totalPages);
          }
          if (searchParams.get("page") === "0") {
            setCurrentPage(1);
            navigate("/listings/featured-listings?page=1");
          }
        }
        setFeaturedRequests(data.success.data);
      })
      .catch((err) => {
        setIsLoading(false);
        setFeaturedRequests([]);
        const tempErr = err.response.data.error;
        if (tempErr.status === 404 && tempErr.message === "No Record Found") {
        }
      });
  }

  useEffect(() => {
    setIsLoading(true);
    fetchFeaturedListings();
  }, [currentPage, noOfPages, sizeOfPages, statusSearch, shop_id, toggle, orderBy]);

  useEffect(() => {
    if (currentPage === 1) {
      refresh((prev) => !prev);
    } else {
      changePage(1);
    }
  }, []);

  function requestAllHistory() {
    const query = new URLSearchParams({
      ...(statusSearch?.label === "All" ? {} : { status: statusSearch?.label }),
    });
    return axios.get(process.env.REACT_APP_API_URL + `/listings/featured-booking?${query}`, { withCredentials: true });
  }
  const remove = async (id) => {
    try {
      const response = await axios.delete(process.env.REACT_APP_API_URL + `/listings/featured-booking/${id}`, { withCredentials: true });
      if (response.status === 200) {
        refresh((prev) => !prev);
        toast.success(`Booking request deleted successfully`, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1500, pauseOnHover: false, className: "toastMsg" });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1500, pauseOnHover: false, className: "toastMsg" });
    }
  };
  const handleAddFeaturedListings = () => {
    setFeaturedListingsModalShow(true);
  };
  const edit = async (id) => {
    const data = featuredRequests.filter((item) => item.id === id)[0];
    setRequestData(data);
    handleAddFeaturedListings();
  };

  const caret = async (bookingId) => {
    const data = featuredRequests.filter((item) => item.id === bookingId);
    const listings = data[0].listings.map((item) => ({ title: item.listing_title, status: item.activation_date !== null ? "Active" : "Deactivated" }));

    const bookingObj = {
      ...(data[0].trxData.length > 0
        ? {
            "Transaction Details": (
              <>
                <div>Total Amount : Rs.{data[0].trxData[0].trx_amount}</div>
                <div>Status :&nbsp;{data[0].trxData[0].trx_status}</div>
                <div>Transaction Date :&nbsp;{dateTimeForInvoice(data[0].trxData[0].trx_datetime).toISOString().split("T")[0]}</div>
              </>
            ),
          }
        : {}),
      ...(data[0].listings
        ? {
            "listing(s)": (
              <>
                <ul>
                  {listings.map((item, index) => {
                    return (
                      <>
                        <li key={index}>{item.title}</li>
                      </>
                    );
                  })}
                </ul>
              </>
            ),
          }
        : {}),
    };
    setBookingData(bookingObj);
  };
  return (
    <>
      {toogleMobileSearch === "select" ? (
        <MobileSearchAcoordian SearchData={SearchData} toggleSearchclose={handleAcoordianCLose} filter={filter} />
      ) : (
        <>
          <Container fluid>
            <Row className="d-flex align-items-center">
              <Col xs lg="3" xl="2" className="d-none d-lg-inline mb-3">
                <SearchableSelect
                  placeholder="Filter Status"
                  searchable={false}
                  value={statusSearch}
                  options={[
                    { value: null, label: "All" },
                    { value: "Pending Confirmation", label: "Pending Confirmation" },
                    { value: "Pending Payment", label: "Pending Payment" },
                    { value: "Approved", label: "Approved" },
                    { value: "Rejected", label: "Rejected" },
                    { value: "Concluded", label: "Concluded" },
                  ]}
                  handleChange={searchStatus}
                />
              </Col>
              {/* For Mobile screen Start */}

              <Col className="d-none d-sm-inline d-lg-none d-md-inline px-0" sm={2}></Col>
              <Col xs="6" lg="3" xl="2" sm="3" className="mb-3">
                <SearchableSelect
                  selectClassName={"bg-white"}
                  placeholder={`Showing ${sizeOfPages}`}
                  defaultValue={`Showing ${sizeOfPages}`}
                  value={`Showing ${sizeOfPages}`}
                  searchable={false}
                  options={[
                    { value: "10", label: "10" },
                    { value: "20", label: "20" },
                    { value: "30", label: "30" },
                  ]}
                  handleChange={(e) => setSizeOfPages(e.value)}
                />
              </Col>
              <Col className={`d-lg-none d-inline mb-3 ${filterStyle.filter}`} xs="2" lg="2">
                <Link to="" className={`${filterStyle.filterToggleButtonLink}`}>
                  <Button onClick={() => handleAcoordianOpen("select")} variant="outline-primary" className={`${filterStyle.filterToggleButton} d-flex align-items-center`}>
                    <img className="" src={filterr} alt="filter" />
                    <span className=" ms-2 d-none d-sm-inline">Filter</span>
                  </Button>
                </Link>
              </Col>
              <Col xs="2" sm="3" lg="2" xl="2" xxl="2" className={`mb-3 p-0 ${filterStyle.addbtn}`}>
                <Button
                  type="submit"
                  style={{
                    fontSize: "1rem",
                    height: "100%",
                  }}
                  className={` ${filterStyle.addButton}`}
                  onClick={handleAddFeaturedListings}
                >
                  <span className="d-none d-sm-inline  d-lg-inline">Featured Listings Request</span> <FontAwesomeIcon icon={faPlus} />
                </Button>
              </Col>

              {/* For Mobile screen End */}
            </Row>
            <div className="pt-2">
              <Button size="sm" type="submit" style={{ fontSize: "14px" }} onClick={resetFilterHandle}>
                <span className="d-lg-inline">Reset Filters</span>{" "}
              </Button>
            </div>

            {isLoading ? (
              <div
                style={{
                  height: "50vh",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <LoaderPage />
              </div>
            ) : !featuredRequests[0] ? (
              <NoResult />
            ) : (
              <>
                <CustomizeTableview
                  className={"mt-3"}
                  data={featuredRequests}
                  columnNotShow={["id", "status", "listings", "trxData"]}
                  caret={caret}
                  actions={featuredRequests.some((row) => row.status === "Pending Confirmation" || (row.status === "Pending Payment" && row.trxData.length === 0) || (row.status === "Pending Payment" && row.trxData.length === 0))}
                  caretData={bookingData}
                  datetimeColumn={["bookingDatetime"]}
                  // sortColumn={["bookingDatetime", "bookingDurationDay", "bookingAmount"]}
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                  edit={{ action: edit, isShow: (row) => row.status === "Pending Confirmation" }}
                  remove={{ action: remove, isShow: (row) => row.status === "Pending Confirmation" || (row.status === "Pending Payment" && row.trxData.length === 0) }}
                  status={{
                    column: ["status"],
                    style: ({ status }) => {
                      if (status === "Approved") {
                        return "text-success";
                      } else if (status === "Pending Confirmation") {
                        return "text-warning";
                      } else if (status === "Pending Payment") {
                        return "text-info";
                      } else if (status === "Rejected") {
                        return "text-danger";
                      } else if (status === "Concluded") {
                        return "text-primary";
                      }
                    },
                    format: ({ status }) => {
                      if (status === "Approved") {
                        return "Approved";
                      } else if (status === "Pending Confirmation") {
                        return "Pending Confirmation";
                      } else if (status === "Pending Payment") {
                        return "Pending Payment";
                      } else if (status === "Rejected") {
                        return "Rejected";
                      } else if (status === "Concluded") {
                        return "Concluded";
                      }
                    },
                  }}
                  actionButton={{
                    column: ["status", "trxData"],
                    label: (value) => {
                      return "Add Payment";
                    },
                    onClick: (er, val) => {
                      if (val.status === "Pending Payment") {
                        setPaymentModal(true);
                        setRequestData(val);
                      }
                    },
                    variant: "primary",
                    style: { padding: "6px 18px" },
                    isShow: (_, row) => {
                      return row.status === "Pending Payment" && row.trxData.length === 0;
                    },
                  }}
                />
                <Row className="mt-5 align-items-center">
                  <Col className="d-none d-md-block" md="3" lg="3">
                    <span className="text-secondary fs-6">{totalFeaturedRequest} result(s)</span>
                  </Col>
                  <Col xs="12" md="6" lg="6">
                    <PaginationBar currentPage={currentPage} noOfPages={noOfPages} changePage={changePage} />
                  </Col>
                  <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
                    <span className="text-secondary fs-6">{totalFeaturedRequest} result(s)</span>
                  </Col>
                  <Col xs="6" md="3" lg="3" className="text-end mt-3">
                    <Csv
                      filename={`${
                        "Featured Requests " +
                        shopName +
                        " " +
                        new Date().toLocaleDateString("en-US", {
                          timeZone: "Asia/Karachi",
                        })
                      }`}
                      api={requestAllHistory}
                      responseObject={({ data }) => {
                        return data.success.data;
                      }}
                      format={(data) => {
                        const { id, bookingDatetime, listings, trxData, ...temp } = data;
                        const newObj = {
                          ...temp,
                          "Listing(s)": listings
                            .map((item) => {
                              return item.listing_title;
                            })
                            .join(";"),
                          "Transaction Details": trxData.length > 0 ? `Total Amount : Rs.${trxData[0].trx_amount} - Status: ${trxData[0].trx_status} - Transaction Date: ${dateTimeForInvoice(trxData[0].trx_datetime).toISOString().split("T")[0]}` : "",
                          bookingDatetime: bookingDatetime.split("T")[0],
                        };
                        return newObj;
                      }}
                      error={(err) => {
                        const tempErr = err.response.data.error;
                        if (tempErr.status === 404 && tempErr.message === "No Record Found") {
                          return true;
                        } else {
                          return false;
                        }
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </>
      )}
      <FeaturedItemsRequestModal
        show={featuredListingsModalShow}
        onHide={() => {
          setFeaturedListingsModalShow(false);
          setRequestData(null);
        }}
        refresh={() => refresh((prev) => !prev)}
        requestData={requestData}
      />
      <PaymentModal
        show={paymentModal}
        onHide={() => {
          setPaymentModal(false);
          setRequestData(null);
        }}
        refresh={() => refresh((prev) => !prev)}
        requestData={requestData}
      />
    </>
  );
};

export default FeaturedListings;
