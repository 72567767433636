import React, { useState } from "react";
import style from "../ListingAddItem/listingAddItem.module.css";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import CustomizeTableview from "../../TableViews/CustomizeTableview";
import ListingHeader from "../ListingHeader/ListingHeader";
import NoResult from "../../NoResult";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import LoaderPage from "../../loaders/loaderPage";
import LoaderOverlay from "../../loaders/loaderOverlay";
import { toast } from "react-toastify";
import PaginationBar from "../../PaginationBar";

const FeaturedItemsRequestModal = ({ show, onHide, refresh, requestData }) => {
  const { user } = useSelector((state) => state.auth);
  const userId = user.user_id;
  const shopId = user.shop_id;
  const [items, setItems] = useState([]);
  const [noOfItems, setNoOfItems] = useState(0);
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchField, setSearchField] = useState(null);
  const [category, setCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [orderBy, setOrderBy] = useState({ created_at: "DESC" });
  const [duration, setDuration] = useState("");
  const [validated, setValidated] = useState(false);
  useEffect(() => {
    if (requestData) {
      setDuration(requestData.bookingDurationDay);
      setSelectedItems(requestData.listings);
    }
  }, [requestData]);
  const changePage = (page) => {
    setCurrentPage(page);
  };
  const close = () => {
    setSelectedItems([]);
    setDuration("");
    onHide();
    setValidated(false);
  };

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const paramsObj = {
          page: currentPage,
          size: sizeOfPages,
          search: searchField || "",
          ...(category !== null && { category: category.value }),
          orderBy: orderBy,
          active: true,
        };

        paramsObj.orderBy = new URLSearchParams(orderBy).toString();
        const searchParams = new URLSearchParams(paramsObj).toString();
        const response = await axios.get(process.env.REACT_APP_API_URL + `/listings?${searchParams}`, {
          withCredentials: true,
        });
        setItems(response?.data?.success?.data);
        setNoOfItems(response?.data?.success?.count?.no_of_items);
        setNoOfPages(response?.data?.success?.count?.no_of_pages);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    };

    if (userId && show) {
      setIsLoading(true);
      fetchItems();
    }
  }, [userId, show, searchField, currentPage, sizeOfPages, category, orderBy]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(+duration, typeof +duration, duration);
    setValidated(true);
    if (selectedItems.length > 0 && selectedItems.length < 11 && duration !== "" && +duration !== 0) {
      setIsSubmitting(true);
      try {
        const listingIds = selectedItems.map((item) => item.id);
        let response;
        if (requestData) {
          response = await axios.put(process.env.REACT_APP_API_URL + `/listings/featured-booking/${requestData.id}`, { listingIds, duration: +duration, shopId }, { withCredentials: true });
          if (response?.data?.success) {
            toast.success(response?.data?.success.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 1500,
              pauseOnHover: false,
              className: "toastMsg",
            });
            refresh();
            setIsSubmitting(false);
            close();
          }
        } else {
          response = await axios.post(process.env.REACT_APP_API_URL + "/listings/featured-booking", { listingIds, duration: +duration, shopId }, { withCredentials: true });
          if (response?.data?.success) {
            toast.success(response?.data?.success.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 1500,
              pauseOnHover: false,
              className: "toastMsg",
            });
            refresh();
            setIsSubmitting(false);
            close();
          }
        }
      } catch (error) {
        if (error?.response?.data?.error) {
          toast.error(error?.response?.data?.error.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1500,
            pauseOnHover: false,
            className: "toastMsg",
          });
          setIsSubmitting(false);
        }
      }
    } else if (selectedItems.length === 0) {
      toast.error(`Please select at least one item`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1500,
        pauseOnHover: false,
        className: "toastMsg",
      });

      setIsSubmitting(false);
    } else if (selectedItems.length > 10) {
      toast.error("Please select maximum of 10 items", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1500,
        pauseOnHover: false,
        className: "toastMsg",
      });
      setIsSubmitting(false);
    }
  };
  return (
    <>
      <LoaderOverlay loading={isSubmitting} />

      <Modal show={show} onHide={close} size={"lg"} centered fullscreen="lg-down" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Add Featured Listings Request</Modal.Title>
        </Modal.Header>

        <Modal.Body className={`p-0 overflow-auto`}>
          <div className={`pt-2 px-sm-4`} style={{ borderBottom: "1px solid #DEDFE4" }}>
            <Row>
              <Col md={6} sm={12}>
                <Form.Group className="mb-3" controlId="formBasicShopName">
                  <Form.Label className="mb-1">
                    Duration<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Duration(in Days)"
                    min={1}
                    max={10}
                    value={duration}
                    onChange={(e) => {
                      setDuration(e.target.value);
                    }}
                    name="duration"
                    required
                    isInvalid={validated && (duration === "" || duration == "0")}
                  />
                  <Form.Control.Feedback type="invalid">Please add duration.</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1">
                    Booking Date<span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Control type="date" value={new Date().toISOString().split("T")[0]} placeholder="Select date" required disabled />
                  <Form.Control.Feedback type="invalid">Please Select Booking Date.</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </div>
          <div className={`pt-2 p-sm-4`} style={{ borderBottom: "1px solid #DEDFE4" }}>
            <ListingHeader hideButton={true} hideTitle={true} hideShowing={true} availableItems={noOfItems} setSearchText={setSearchField} handleCategoryFilter={setCategory} />
          </div>
          <div className={`${style.tableContainer} overflow-auto`}>
            {isLoading ? (
              <LoaderPage />
            ) : noOfItems === 0 || items.length === 0 ? (
              <NoResult name="Active Listings" />
            ) : (
              <CustomizeTableview
                data={items}
                columnNotShow={["id", "category_id", "listed_quantity", "created_at", "updated_at", "brand_id", "archived_on", "activation_date", "no_of_lead", "no_of_views", "product_url", "sale_price", "status", "stock_quantity", "item_id", "is_deleted", "image", "online_discount_unit"]}
                thumbnail={{
                  title: "title",
                  img: "image",
                  description: "title",
                }}
                checkbox={{ data: selectedItems, setter: setSelectedItems }}
                actions={false}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className={`d-flex flex-column gap-1 p-4`}>
          <div className={`d-flex gap-1 align-item-end justify-content-between w-100`}>
            <span
              className=""
              style={{
                color: "#39414A",
                textAlign: "center",
                paddingTop: "14px",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "18px",
              }}
            >
              <FontAwesomeIcon icon={faCheck} className={`me-2`} />
              {selectedItems?.length} Items Selected
            </span>
            {noOfItems > 0 ? <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} /> : ""}
          </div>

          <Button className={`w-100`} onClick={handleSubmit} disabled={isSubmitting}>
            {requestData ? `Update Request` : `Send Request`}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FeaturedItemsRequestModal;
