import axios from "axios";

export const getcomponent = async (component, filters = {}, dropdown = false, page, size, search = "") => {
  try {
    const queryString = new URLSearchParams({
      ...(filters ? filters : {}),
      ...(dropdown ? { dropdown } : {}),
      ...(search ? { search } : {}),
      ...(page ? { page } : {}),
      ...(size ? { size } : {}),
    });
    const data = axios.get(`${process.env.REACT_APP_API_URL}/component/${component}${queryString?.toString()?.length ? `?${queryString.toString()}` : ""}`, {
      withCredentials: true,
    });
    return data;
  } catch (error) {
    return error;
  }
};
