import react, { useEffect, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import style from "../../styles/Reports.module.css";
import styles from "../../styles/Setting.module.css";
import SearchableSelect from "../SearchableSelect";
import DatePicker from "../DatePicker/DatePicker";
import moment from "moment/moment";
import axios from "axios";
import { useSelector } from "react-redux";
import CustomizeTableview from "../TableViews/CustomizeTableview";
import LoaderPage from "../loaders/loaderPage";
import PaginationBar from "../PaginationBar";
import ReactSelect from "../ReactSelect";
import MobilrReportsFilter from "./MobileReportsFilter";
import BankTransactionsReportTour from "../../TourGuides/BankTransactionsReportTour";
const BankTransactionsReport = ({ fromDate = moment().subtract(29, "days").startOf("day")._d, toDate = moment().endOf("day")._d, setToDate, setFromDate, close, show, MainactiveTab }) => {
  const { user } = useSelector((state) => state.auth);
  const userId = user.user_id;
  const user_type = user.user_type;
  const shop_id = user.shop_id;
  const [location, setLocation] = useState({});
  let [locationFields, setLocationFields] = useState({});
  const [activeTab, setActiveTab] = useState("Bank-Account-Wise");
  const [tableData, setTableData] = useState([]);
  const [bank, setBank] = useState({});
  const [paymentType, setPaymentType] = useState({ value: null, label: "All" });
  const [transactionMedium, setTransactionMedium] = useState({ value: null, label: "All" });
  const [transactionSource, setTransactionSource] = useState({ value: null, label: "All" });
  const [filterInput, setFilterInput] = useState("");
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);

  const [showMobileTabs, setShowMobileTabs] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const dateObj = new Date();
  const year = dateObj.getUTCFullYear();
  const [orderBy, setOrderBy] = useState({ transaction_date: "DESC" });

  var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  var monthName = months[dateObj.getMonth()];

  const convertDate = (date) => {
    const inputDate = new Date(date);
    const formattedDate = months[inputDate.getMonth()] + " " + inputDate.getDate() + " " + inputDate.getFullYear();
    return formattedDate;
  };

  const handleLocation = (e) => {
    if (e.value) {
      setLocationFields(e);
    } else {
      setLocationFields({});
    }
  };
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  const handleShow = () => {
    setShowMobileTabs(true);
  };
  const handleClose = () => {
    setShowMobileTabs(false);
  };
  useEffect(() => {
    axios
      .post(process.env.REACT_APP_API_URL + `/location/get-location-by-user/${userId}`, { shop_id: shop_id, user_type: user_type }, { withCredentials: true })
      .then((res) => {
        const locationsArr = res.data.map((loc) => ({
          value: loc.location_id,
          label: loc.location,
          isDisabled: new Date().toISOString() >= loc.active_till ? true : false,
        }));

        setLocation(locationsArr);
        setLocationFields({
          value: locationsArr[0]?.value,
          label: locationsArr[0]?.label,
          isDisabled: locationsArr[0]?.isDisabled,
        });
      })
      .catch((err) => {
        throw new Error(err);
      });
  }, [userId, shop_id, user_type]);
  const fetchData = (locationFields) => {
    const orderByFormatForQueryString = (obj) => {
      const [[key, value]] = Object.entries(obj);
      return { [`orderBy[${key}]`]: value };
    };
    const orderByObject = orderBy ? orderByFormatForQueryString(orderBy) : "";
    const filter = new URLSearchParams({
      ...(currentPage ? { page: currentPage } : {}),
      ...(sizeOfPages ? { size: sizeOfPages } : {}),
      ...(locationFields?.value ? { location_id: locationFields?.value } : {}),
      ...(fromDate && toDate ? { "date[from]": fromDate.toISOString(), "date[to]": toDate.toISOString() } : {}),
      ...(orderBy ? orderByObject : {}),
      ...(activeTab === "Bank-Account-Wise" ? { bankWise: true, ...(bank?.value ? { bankAccount: bank.value } : {}) } : {}),
      ...(activeTab === "Payment-Type-Wise" ? { typeWise: true, ...(paymentType?.value ? { type: paymentType.value } : {}) } : {}),
      ...(activeTab === "Transaction-Medium-Wise" ? { transactionMediumWise: true, ...(transactionMedium?.value ? { transactionMedium: transactionMedium.value } : {}) } : {}),
      ...(activeTab === "Transaction-Source-Wise" ? { transactionSourceWise: true, ...(transactionSource?.value ? { transactionSource: transactionSource.value } : {}) } : {}),
    });
    axios
      .get(process.env.REACT_APP_API_URL + `/reports/bank-transactions/${shop_id}${filter?.toString()?.length ? `?${filter.toString()}` : ""}`, {
        withCredentials: true,
      })
      .then((res) => {
        setTableData(res.data?.data);
        setCount(res.data?.count?.no_of_items);
        setNoOfPages(res.data?.count?.no_of_pages);
        setIsLoading(false);
      })
      .catch((err) => {
        throw new Error(err.response.data);
      });
  };

  useEffect(() => {
    if (Object.keys(locationFields).length > 0) {
      fetchData(locationFields);
    }
  }, [shop_id, locationFields, orderBy, fromDate, toDate, activeTab, paymentType, filterInput, bank, transactionMedium, transactionSource]);

  const changePage = (page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    setCurrentPage(1);
  }, [shop_id, fromDate, toDate, location, activeTab]);
  const resetFilter = () => {
    setBank({ value: null, label: "All" });
    setPaymentType({ value: null, label: "All" });
    setTransactionMedium({ value: null, label: "All" });
    setTransactionSource({ value: null, label: "All" });
  };
  useEffect(() => {
    resetFilter();
  }, [activeTab, shop_id]);
  return (
    <>
      {user?.tour && !user?.tour?.BankTransactionsReport && <BankTransactionsReportTour />}
      <Row className="align-items-center justify-content-start px-2 py-2 bg-white mb-3 mt-1 d-none d-sm-flex" id="bank-transactions-report-filter">
        <Col md="4" sm="4" lg="3" xl="2" className="position-relative mb-md-0 mb-2">
          <DatePicker fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
        </Col>
        <Col className="mb-2 mb-md-0" md="4" sm="4" lg="3" xl="2">
          <SearchableSelect placeholder="Select Location" searchable={false} options={location} handleChange={handleLocation} value={Object.keys(locationFields).length > 0 ? locationFields : null} style={{ width: "100px" }} disabled={!location.length} />
        </Col>
        <Col className="mb-2 mb-md-0">
          <p className="fw-bold fs-16 ms-2 float-end p-0 my-0">{`${convertDate(fromDate)}` === `${convertDate(toDate)}` ? convertDate(toDate) : `${convertDate(fromDate)} - ${convertDate(toDate)}`} </p>{" "}
        </Col>
      </Row>
      <Row className="bg-white p-1 mb-3 overflow-auto text-nowrap ob ">
        <Col xl="11" lg="11" md="12">
          <Button
            id="bank-transactions-report-bank-account"
            onClick={() => {
              handleTabChange("Bank-Account-Wise");
            }}
            className={`${styles.btn} ${activeTab === "Bank-Account-Wise" ? styles.activebtn : ""}  my-2 mx-1 rounded-5 `}
            variant="outline-primary"
          >
            Bank Account
          </Button>
          <Button
            id="bank-transactions-report-payment-type"
            onClick={() => {
              handleTabChange("Payment-Type-Wise");
            }}
            className={`${styles.btns} ${activeTab === "Payment-Type-Wise" ? styles.activebtn : ""}  my-2 mx-1 rounded-5`}
            variant="outline-primary"
          >
            Payment Type
          </Button>
          <Button
            id="bank-transactions-report-transaction-medium"
            onClick={() => {
              handleTabChange("Transaction-Medium-Wise");
            }}
            className={`${styles.btns} ${activeTab === "Transaction-Medium-Wise" ? styles.activebtn : ""}  my-2 mx-1 rounded-5`}
            variant="outline-primary"
          >
            Transaction Medium
          </Button>
          <Button
            id="bank-transactions-report-transaction-source"
            onClick={() => {
              handleTabChange("Transaction-Source-Wise");
            }}
            className={`${styles.btns} ${activeTab === "Transaction-Source-Wise" ? styles.activebtn : ""} my-2 mx-1 rounded-5`}
            variant="outline-primary"
          >
            Transaction Source
          </Button>
        </Col>
      </Row>
      <div className="d-flex flex-column flex-md-row">
        <div className="bg-white p-3 w-100 fs-20 fw-bold">
          {activeTab.replace(/-/g, " ").replace("Wise", "")}
          <p className={`text-start m-0 p-0`} style={{ fontSize: "13px", fontWeight: "400" }}>
            {activeTab === "Bank-Account-Wise"
              ? "The report provides a complete overview of all transactions related to Bank Accounts."
              : activeTab === "Payment-Type-Wise"
              ? "The report provides a complete overview of all transactions related to Payment Types."
              : activeTab === "Transaction-Medium-Wise"
              ? "The report provides a complete overview of all transactions related to Transaction Medium Wise."
              : "The report provides a complete overview of all transactions related to Transaction Source."}
          </p>
        </div>

        <div className="bg-white p-3">
          {activeTab === "Bank-Account-Wise" && (
            <div style={{ minWidth: "220px" }} className="me-3 w-100">
              <ReactSelect
                placeholder="Select Bank"
                searchable={false}
                value={Object.keys(bank).length > 0 ? bank : null}
                options={() => {
                  return new Promise((resolve, reject) => {
                    axios
                      .get(process.env.REACT_APP_API_URL + `/shops/banks/${shop_id}`, {
                        withCredentials: true,
                      })
                      .then((res) => {
                        const getData = res.data.map((bank) => ({
                          value: bank.bank_id,
                          label: bank.bank_name,
                        }));
                        getData.splice(0, 0, { label: "All", value: null });
                        resolve(getData);
                      })
                      .catch((err) => {
                        reject("err" + err);
                      });
                  });
                }}
                handleChange={(e) => {
                  if (e.value) {
                    setBank(e);
                  } else {
                    setBank({ value: null, label: "All" });
                  }
                }}
                onInputChange={(newInput) => {
                  const inputValue = newInput.replace(/\s+/g, " ").trim();
                  setFilterInput(inputValue);
                  return inputValue;
                }}
              />
            </div>
          )}
          {activeTab === "Payment-Type-Wise" && (
            <div style={{ minWidth: "220px" }} className="me-3 w-100">
              <SearchableSelect
                placeholder="Select Payment Type"
                options={[
                  { value: null, label: "All" },
                  { value: "CR", label: "Credit" },
                  { value: "DR", label: "Debit" },
                ]}
                handleChange={(e) => {
                  if (e.value) {
                    setPaymentType(e);
                  } else {
                    setPaymentType({ value: null, label: "All" });
                  }
                }}
                value={Object.keys(paymentType).length > 0 ? paymentType : null}
                onInputChange={(newInput) => {
                  const inputValue = newInput.replace(/\s+/g, " ").trim();
                  setFilterInput(inputValue);
                  return inputValue;
                }}
              />
            </div>
          )}
          {activeTab === "Transaction-Medium-Wise" && (
            <div style={{ minWidth: "220px" }} className="me-3 w-100">
              <SearchableSelect
                placeholder="Select Transaction Medium"
                options={[
                  { value: null, label: "All" },
                  { value: "transfer", label: "Transfer" },
                  { value: "cheque", label: "Cheque" },
                  { value: "card", label: "Card" },
                  { value: "cash-deposit", label: "Cash-deposit" },
                ]}
                handleChange={(e) => {
                  if (e.value) {
                    setTransactionMedium(e);
                  } else {
                    setTransactionMedium({ value: null, label: "All" });
                  }
                }}
                value={Object.keys(transactionMedium).length > 0 ? transactionMedium : null}
                onInputChange={(newInput) => {
                  const inputValue = newInput.replace(/\s+/g, " ").trim();
                  setFilterInput(inputValue);
                  return inputValue;
                }}
              />
            </div>
          )}
          {activeTab === "Transaction-Source-Wise" && (
            <div style={{ minWidth: "220px" }} className="me-3 w-100">
              <SearchableSelect
                placeholder="Select Transaction Source"
                options={[
                  { value: null, label: "All" },
                  { value: "EX", label: "Expense" },
                  { value: "PO", label: "Purchase Order" },
                  { value: "CP", label: "Customer Payment" },
                  { value: "REF", label: "Refund" },
                  { value: "TR", label: "Trade In" },
                  { value: "Capital", label: "Capital" },
                ]}
                handleChange={(e) => {
                  if (e.value) {
                    setTransactionSource(e);
                  } else {
                    setTransactionSource({ value: null, label: "All" });
                  }
                }}
                value={Object.keys(transactionSource).length > 0 ? transactionSource : null}
                onInputChange={(newInput) => {
                  const inputValue = newInput.replace(/\s+/g, " ").trim();
                  setFilterInput(inputValue);
                  return inputValue;
                }}
              />
            </div>
          )}
        </div>
      </div>
      {isLoading ? (
        <div style={{ height: "50vh" }} className="">
          <LoaderPage />
        </div>
      ) : tableData.length > 0 ? (
        <>
          <div className={style.brdrd}>
            <CustomizeTableview className={"mt-1"} data={tableData} columnNotShow={["id", "location_nick"]} actions={false} dateColumn={["transaction_date"]} sortColumn={["transaction_date"]} orderBy={orderBy} setOrderBy={setOrderBy} />
          </div>
          <Row className="mt-5 align-items-center">
            <Col className="d-none d-sm-block" sm="2" md="3" lg="3">
              <span className="text-secondary fs-6">
                {count} {count > 1 ? "results" : "result"}
              </span>
            </Col>
            <Col xs="12" sm="7" md="6" lg="6">
              <PaginationBar currentPage={currentPage} noOfPages={noOfPages} changePage={changePage} />
            </Col>
            <Col className="d-sm-none d-flex align-items-center mt-3" xs="6" lg="3">
              <span className="text-secondary fs-6">
                {count} {count > 1 ? "results" : "result"}{" "}
              </span>
            </Col>
          </Row>
        </>
      ) : (
        <Row className={`${style.noDataAccordian} fw-bold fs-16 d-flex justify-content-center mt-2 py-3`} style={{ marginBottom: "2rem" }}>
          No data found
        </Row>
      )}
      <MobilrReportsFilter activeTab={MainactiveTab} handleLocation={handleLocation} locationFields={locationFields} location={location} show={show} handleClose={close} toDate={toDate} fromDate={fromDate} setToDate={setToDate} setFromDate={setFromDate} />
    </>
  );
};
export default BankTransactionsReport;
