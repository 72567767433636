import React, { useState } from "react";
import { Form, Row, Col, Button, Modal } from "react-bootstrap";
import { useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";

const PaymentModal = ({ show, onHide, refresh, requestData }) => {
  const { user } = useSelector((state) => state.auth);
  const shop_id = user?.shop_id;
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState("");
  const [paymentType, setPaymentType] = useState("transfer");
  const [paidAmount, setPaidAmount] = useState();
  const [transDate, setTransDate] = useState(new Date().toISOString().split("T")[0]);
  const [transactionId, settransactionId] = useState();
  const [paidCheck, setPaidCheck] = useState(false);
  const [selectImage, setSelectImag] = useState();
  const [selectImageURL, setSelectImagURL] = useState();

  const handleImageUpload = async (e) => {
    const formData = new FormData();
    formData.append("files", e.target.files[0], e.target.files[0].name);
    setSelectImag(formData);
    setSelectImagURL(URL.createObjectURL(e.target.files[0]));
  };
  const handleRequestSubmit = async (e) => {
    e.preventDefault();
    setValidated(true);
    if (shop_id && paidAmount && paymentType && transactionId && transDate && selectImage) {
      if (+paidAmount !== +requestData?.bookingAmount) {
        setPaidCheck(true);
        return;
      }
      try {
        let imgData;
        if (selectImage) {
          imgData = await axios.post(`${process.env.REACT_APP_URL_IMAGE_CDN}/images/transactions/${shop_id}`, selectImage, { withCredentials: true });
        }

        let trxData = {
          trx_amount: +paidAmount,
          trx_proof: imgData ? imgData.data.files[0] : null,
          trx_datetime: transDate ? transDate : new Date().toISOString().split("T")[0],
          trx_id: transactionId ? transactionId : null,
          payment_type: "Bank Transfer",
        };
        const res = await axios.post(process.env.REACT_APP_API_URL + `/listings/featured-booking/${requestData?.id}/add-trx`, { trxData }, { withCredentials: true });
        if (res) {
          toast.success("Request Added Successfully", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          refresh();
          onHide();
        }
      } catch (err) {
        setError(err.response.data.error.message);
      }
    }
  };

  return (
    <>
      <Modal show={show} onHide={onHide} animation={true} centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Add Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-4 mb-2">
          <Form noValidate validated={validated} onSubmit={handleRequestSubmit}>
            {error && <div className="text-danger text-center my-1 rounded-1 w-100">*{error}</div>}

            <Row className="">
              <Col md={6} className="py-2">
                <Form.Group className="w-100" controlId="formBasicShopName">
                  <label>Shop</label>
                  <Form.Control value={user?.shop_name} name="shop_name" disabled={true} />
                </Form.Group>
              </Col>
              <Col md={6} className="py-2">
                <Form.Group className="w-100" controlId="price">
                  <label>
                    Amount Payable <span className="text-danger">*</span>
                  </label>
                  <Form.Control value={requestData?.bookingAmount} type="number" placeholder="Rs.1000" disabled />
                </Form.Group>
              </Col>
              <Col md={6} className="py-2">
                {" "}
                <Form.Group className="w-100" controlId="price">
                  <label>
                    Paid Amount <span className="text-danger">*</span>
                  </label>
                  <Form.Control
                    value={paidAmount ? paidAmount : ""}
                    placeholder="Rs.1000"
                    required
                    onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                    min={Number(requestData?.bookingAmount)}
                    max={Number(requestData?.bookingAmount)}
                    onChange={(e) => {
                      if (Number(e.target.value) > 0) {
                        setPaidAmount(e.target.value);
                        if (Number(requestData?.bookingAmount) === Number(e.target.value)) {
                          setPaidCheck(false);
                        } else {
                          setPaidCheck(true);
                        }
                      } else {
                        setPaidAmount(0);
                      }
                    }}
                  />

                  <Form.Control.Feedback type="invalid">Please Enter Amount</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Form.Group className="mt-2" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                <label>
                  Transaction Id <span className="text-danger">*</span>
                </label>
                <Form.Control
                  value={transactionId}
                  name="contact_person_name"
                  onChange={(e) => {
                    settransactionId(e.target.value);
                  }}
                  placeholder="eg.AA11"
                  required={paymentType === "transfer" ? true : false}
                />
                <Form.Control.Feedback type="invalid">Please Enter Transaction Id.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mt-1" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                <label>
                  Transaction Date<span className="text-danger">*</span>
                </label>
                <Form.Control value={transDate} name="trans_date" disabled={true} required={paymentType === "transfer" ? true : false} type="date" />

                <Form.Control.Feedback type="invalid">Please select Date.</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mt-1" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                <label className="mb-1">
                  Transaction Proof<span className="text-danger">*</span>
                </label>
                <Form.Control type="file" size="sm" accept="image/*" name="contact_person_name" onChange={(e) => handleImageUpload(e)} required={paymentType === "transfer" ? true : false} />
                <Form.Control.Feedback type="invalid">Please upload image.</Form.Control.Feedback>
              </Form.Group>
              <div className="mt-2 d-flex align-items-center justify-content-center w-100 ">
                <div style={{ width: "150px" }}>
                  <img src={selectImageURL} alt="" width="100%" height="auto" />
                </div>
              </div>
              {paidCheck && (
                <div class="alert alert-danger  text-center w-100 mt-2" role="alert">
                  Paid amount must be equal to payable
                </div>
              )}
            </Row>
            <Row className="py-3">
              <Col>
                <Button className="w-100" onClick={handleRequestSubmit} disabled={!paymentType || paidCheck || !paidAmount || !transactionId || !transDate || !selectImageURL}>
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PaymentModal;
