import React, { useEffect, useState } from "react";
import style from "../../styles/SalesPage.module.css";
import inputstyle from "../../styles/SearchInput.module.css";
import search from "./../../assets/svg/search.svg";
import search1 from "./../../assets/svg/search-1.svg";
import activeTilesViewIcon from "../../assets/svg/activeTilesViewIcon.svg";
import tilesViewIcon from "../../assets/svg/tilesViewIcon.svg";
import smallGridViewIcon from "../../assets/svg/smallGridViewIcon.svg";
import activeSmallGridViewIcon from "../../assets/svg/activeSmallGridViewIcon.svg";
import mediumGridViewIcon from "../../assets/svg/mediumGridViewIcon.svg";
import activeMediumGridViewIcon from "../../assets/svg/activeMediumGridViewIcon.svg";

import { Button, ButtonGroup, Col, Dropdown, FormControl, Row } from "react-bootstrap";
import ReactSelect from "../ReactSelect";
import axios from "axios";
import sortAsc from "../../assets/svg/icon.svg";
function SalesPageFilters({ selectedCategory, accessoryType, setAccessoryType, viewMode, setViewMode, search, setSearch, status, setStatus }) {
  const [screenWidth, setScreenWidth] = useState();
  const [dateToDisplay, setDateToDisplay] = useState("");
  const searchProduct = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    const dateArray = new Date().toDateString().split(" ");
    const date = `${dateArray[2]} ${dateArray[1]} ${dateArray[3]}`;
    setDateToDisplay(date);
    setScreenWidth(window.innerWidth);
  }, []);

  useEffect(() => {}, [status, search]);
  // const handleStatusChange = (newStatus) => {
  //   setStatus(newStatus);
  // };

  return (
    <div>
      <div className={`d-flex justify-content-between align-items-center`}>
        <div className="mb-3" lg={4} md={4} sm={4}>
          <div className={`${style.chooseproducts}`}>Choose products</div>
          <div className="mt-1 mt-md-2">{dateToDisplay}</div>
        </div>
        <ButtonGroup className={`${style.viewModeBtnGroup}`} id="sales-product-view">
          <Button variant="outline-primary" active={viewMode === "tiles"} onClick={() => setViewMode("tiles")}>
            {viewMode === "tiles" ? <img alt="view Icon" src={activeTilesViewIcon} /> : <img alt="view Icon" src={tilesViewIcon} />}
          </Button>
          <Button variant="outline-primary" active={viewMode === "smallGrid"} onClick={() => setViewMode("smallGrid")}>
            {viewMode === "smallGrid" ? <img alt="view Icon" src={activeSmallGridViewIcon} /> : <img alt="view Icon" src={smallGridViewIcon} />}
          </Button>
          <Button variant="outline-primary" active={viewMode === "mediumGrid"} onClick={() => setViewMode("mediumGrid")}>
            {viewMode === "mediumGrid" ? <img alt="view Icon" src={activeMediumGridViewIcon} /> : <img alt="view Icon" src={mediumGridViewIcon} />}
          </Button>
        </ButtonGroup>
      </div>
      <Row className="d-flex justify-content-between">
        <Col
          xs={12}
          sm={6}
          md={6}
          lg={3}
          className="mb-1"
          // style={{ maxWidth: '270px' }}
        >
          <div className="position-relative">
            <FormControl type="search" placeholder="Search Product" className={`${inputstyle.searchInput} ${inputstyle.coloredInput} rounded-2`} aria-label="Search" onChange={searchProduct} id="sales-product-search" />
            <div className={`${inputstyle.searchIcon} d-flex`}>
              <img src={screenWidth > 770 ? search1 : search1} className="ms-2" alt="search" width={"23px"} />
            </div>
          </div>
        </Col>

        {selectedCategory && selectedCategory == 6 ? (
          <Col xs={12} sm={6} md={6} lg={3} className="mb-1">
            <ReactSelect
              placeholder="Filter Accessory"
              searchable={true}
              cache={false}
              options={(e) => {
                return new Promise((resolve, reject) => {
                  axios
                    .get(process.env.REACT_APP_API_URL + `/component/accessory_type?${new URLSearchParams({ dropdown: true }).toString()}`, {
                      withCredentials: true,
                    })
                    .then((res) => {
                      const value = res.data.success.data.map((loc) => ({
                        value: loc.id,
                        label: loc.label,
                      }));
                      value.splice(0, 0, { value: null, label: "All" });
                      const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                      resolve(filteredValue);
                    })
                    .catch((error) => {
                      throw new Error(error);
                    });
                });
              }}
              handleChange={(e) => {
                if (e.value) {
                  setAccessoryType({ value: e.value, label: e.label });
                } else {
                  setAccessoryType(null);
                }
              }}
              value={accessoryType}
              name="Accessory Type"
            />
          </Col>
        ) : (
          ""
        )}

        {/* <Col xs={12} sm={4} md={4} lg={3} className="mb-1">

<Dropdown className={`d-flex justify-content-end ${style.statusDropdown}`}>
  <Dropdown.Toggle
    className="border-0 p-0 text-secondary dropdown-toggle show btn btn-light"
    style={{ background: "transparent" }}
    id="dropdown-basic"
  >
    <div className="p-2 cursor-pointer rounded-top text-nowrap w-30">
      Status <span style={{color:" rgb(149, 55, 255)"}}>{`(${status})`}</span> <img src={sortAsc} alt="Sort Ascending Icon" />
    </div>
  </Dropdown.Toggle>
  <Dropdown.Menu>
    <Dropdown.Item onClick={() => setStatus("Available")}>
      Available
    </Dropdown.Item>
    <Dropdown.Item  onClick={() => setStatus("Sold")}>
      Sold
    </Dropdown.Item>
  </Dropdown.Menu>
</Dropdown></Col> */}
      </Row>
    </div>
  );
}

export default SalesPageFilters;
